import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import './assets/css/bootstrap.min.css';
import './assets/css/icons.css';
import './assets/css/style.css';
import './index.scss';

import { unregister } from './registerServiceWorker';

import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/customisedMUI/theme';

//redux
import { Provider } from 'react-redux';

import store from './store/';

import { GoogleOAuthProvider } from '@react-oauth/google';
const clientId = '651065819092-t97i9tfuknprp3cl13cg2bgf6c3dapvn.apps.googleusercontent.com';
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <GoogleOAuthProvider clientId={clientId}>
                    <App />
                </GoogleOAuthProvider>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

root.render(app);

unregister();
