import axios from 'config/axios';
import setAuthToken from 'utils/setAuthToken';
import store from '../store';
import { GET_ERRORS } from '../store/actions/actionTypes';

class Auth {
    // Check auth token for expiry and existence to run before all API calls
    static async checkCurrentAuthToken() {
        await Auth.setNewAuthToken();
    }

    static async setNewAuthToken() {
        return await Auth.userV2({ redirect: false, history: null });
    }

    static async userV2({ redirect, history }) {
        try {
            return await axios.get('/api/v4/auth/user').then((resp) => {
                if (resp && resp.data) {
                    if (resp.data.error) {
                        throw new Error(resp.data.error);
                    }
                    const { success, token } = resp.data;
                    if (success) {
                        setAuthToken(token);
                        if (redirect && history) history.push('/');
                        if (redirect && !history) window.location.href = '/';
                        return token;
                    }
                } else if (resp && resp.response && +resp.response.status === 401) {
                    localStorage.removeItem('authToken');
                }
            });
        } catch (err) {
            store.dispatch({
                type: GET_ERRORS,
                payload: err.message,
            });
            return { error: err.message };
        }
    }
}

export default Auth;
