import { SET_CURRENT_LESSON, SET_LESSON2_ACTIVITIES, SET_ATTEMPT_ID } from '../actions/actionTypes';

const initialState = {
    seedsCount: 0,
    currentLesson: null,
    attemptId: null,
    l2Activities: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_LESSON:
            return { ...state, currentLesson: action.payload };
        case SET_ATTEMPT_ID:
            return { ...state, attemptId: action.payload };
        case SET_LESSON2_ACTIVITIES:
            return { ...state, l2Activities: action.payload };
        default:
            return state;
    }
}
