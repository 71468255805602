import {
    GET_ERRORS,
    INIT_STUDENTS,
    STUDENT_DONATED,
    UPDATE_STUDENT_AUTH_DATA,
    SET_CLASS,
    BADGE_AWARDED,
} from './actionTypes';
import axios from 'config/axios';
import Notification from '../../components/UI/Notification';
import { showNotification } from './uiActions';

export const getSeedsCount = (studentId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post('/api/v4/users/students/seeds/' + studentId);
            if (result) {
                resolve(result.data);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

export const donate = (data) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post('/api/v4/users/students/donate', {
                id: data.id,
                goalId: data.goalId,
                seeds: data.seeds,
            });

            const badge = result.data.badge;

            if (result) {
                //remove from redux student
                dispatch(donated({ donatedSeeds: result.data.donatedSeeds, studentSeeds: result.data.studentSeeds }));
                Array.isArray(badge.badge) && dispatch({ type: BADGE_AWARDED, payload: badge.badge });
                resolve(result.data);
            }
        } catch (err) {
            console.log('Error donating: ', err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

export const donated = (data) => (dispatch) => {
    dispatch({
        type: STUDENT_DONATED,
        payload: data,
    });

    dispatch({ type: UPDATE_STUDENT_AUTH_DATA, payload: { seeds: data.studentSeeds } });
};

export const endLessonActivityForStudent = (studentId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`/api/v4/lessons/students/endLessonActivity/${studentId}`);
            if (result) {
                resolve(result.data);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

export const getClasses =
    ({ classList, currentClass }) =>
    async (dispatch) => {
        try {
            if (classList) {
                dispatch({
                    type: INIT_STUDENTS,
                    payload: { result: classList, currentClass },
                });

                return classList;
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
        }
    };

export const setClass = (classId) => async (dispatch) => {
    dispatch({
        type: SET_CLASS,
        payload: classId,
    });
};

export const saveAccountSetting = (studentId, settings, data) => async (dispatch) => {
    try {
        const result = await axios.post(`/api/v4/users/student/${studentId}/settings/${settings}`, { data });
        if (result.data === null || result.data === '') {
            dispatch(showNotification(Notification.DANGER, `Failed to save ${settings}`));
        } else {
            dispatch(showNotification(Notification.SUCCESS, `${settings} saved successfully`));
        }
    } catch (err) {
        dispatch(showNotification(Notification.DANGER, `Failed to save ${settings}`));
    }
};

export const getAccountSetting = (studentId, settings) => async (dispatch) => {
    try {
        const result = await axios.get(`/api/v4/users/student/${studentId}/settings/${settings}`);
        if (!result.data) {
            dispatch(showNotification(Notification.DANGER, `Failed to get ${settings}`));
        } else {
            return result.data;
        }
    } catch (err) {
        dispatch(showNotification(Notification.DANGER, `Failed to get ${settings}`));
    }
};

export const removeAccountSetting = (studentId, settings) => async (dispatch) => {
    try {
        const result = await axios.delete(`/api/v4/users/student/${studentId}/settings/${settings}`);

        if (!result) {
            dispatch(showNotification(Notification.DANGER, `Failed to remove ${settings}`));
        } else {
            return result;
        }
    } catch (err) {
        dispatch(showNotification(Notification.DANGER, `Failed to remove ${settings}`));
    }
};
