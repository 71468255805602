import ReactGA from 'react-ga4';
import { CATEGORIES, EVENTS } from 'constants/googleAnalytics';
import axios from 'config/axios';
import setAuthToken from 'utils/setAuthToken';
import { parseJwt } from 'utils';

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    SET_CURRENT_STUDENT,
    SET_UNAUTHORIZED_TEACHER,
    CLEAR_USER,
    UPDATE_STUDENT_AUTH_DATA,
    BADGE_AWARDED,
    SET_LEARNING_SUPPORT,
    SET_CURRENT_GRADEID,
} from './actionTypes';
import { ampli } from 'ampli';

import { showNotification } from 'store/actions/uiActions';
import Notification from 'components/UI/Notification';
import { licenseTypes, roleTypes } from 'constants/options';
import Users from 'api/Users';
// import { teacherAppLink } from 'utils/tools';

export const loginStudent = (studentData, history, loginMethod) => async (dispatch) => {
    try {
        let result;
        ampli.buttonClicked({
            button_name: loginMethod === 'googleLogin' ? 'google_sso' : 'lsgo_login',
            location: 'Student Login',
        });
        if (loginMethod === 'googleLogin') {
            result = await axios.post('/api/v4/auth/google-login', 'idToken=' + studentData);
        } else {
            result = await axios.post('/api/v4/auth/login', studentData);
        }
        if (result && !result.error && !result.response) {
            if (!result.data.success) {
                dispatch({
                    type: GET_ERRORS,
                    payload: result.data.message,
                });
                return result.data;
            } else {
                //Save to local storage
                await axios.get('/api/v4/auth/user').then(async (result) => {
                    if (result && result.data) {
                        if (result.data.error) {
                            return dispatch({
                                type: GET_ERRORS,
                                payload: result.data.error,
                            });
                        }
                        const { token, badge } = result.data;

                        if (badge) {
                            dispatch({
                                type: BADGE_AWARDED,
                                payload: badge.badge,
                            });
                        }

                        //Set token to Auth header
                        setAuthToken(token);
                        //GA custom event for student logging in
                        ReactGA.event({
                            category: CATEGORIES.STUDENT_ACTIONS,
                            action: `${EVENTS.LOG_IN}`,
                        });

                        //Decode token to get user data
                        const decoded = await parseJwt(token.replace('Bearer ', ''));

                        try {
                            const profile = await axios.get(`/api/v4/users/${decoded.id}`);
                            dispatch(setUser({ ...profile.data, alias: decoded.alias }));
                            dispatch(setLearningSupport(profile.data.learningSupport === 1));

                            ampli.identify(profile.data.id, {
                                license: licenseTypes[profile.data.user_groups.licenseId],
                                role: roleTypes[profile.data.user_groups.roleId],
                                payment_status: profile.data.user_groups.paymentStatus || 'free',
                                group_id: roleTypes[profile.data.user_groups.groupId],
                                school_id: profile.data.schoolId,
                            });
                            return profile;
                        } catch (error) {
                            console.log({ error });
                        }
                    } else if (result && result.response && +result.response.status === 401) {
                        localStorage.removeItem('authToken');
                    }
                });
            }
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: result.response.data,
            });
            return result.response.data;
        }
    } catch (err) {
        console.log(err, 'err');
        dispatch({
            type: GET_ERRORS,
            payload: err.message,
        });
    }
};

export const loginStudentQR = (qrToken, history) => async (dispatch) => {
    try {
        ampli.buttonClicked({
            button_name: 'qr_token',
            location: 'Student Login',
        });
        const result = await axios.post('/api/v4/auth/qr-login/', { qrToken });
        if (result) {
            if (!result.data?.success) {
                dispatch({
                    type: GET_ERRORS,
                    payload: result || result.data.error,
                });
                return result.data;
            } else {
                //Save to local storage
                await axios.get('/api/v4/auth/user').then(async (result) => {
                    if (result && result.data) {
                        if (result.data.error) {
                            return dispatch({
                                type: GET_ERRORS,
                                payload: result.data.error,
                            });
                        }
                        const { token, badge } = result.data;

                        if (badge) {
                            dispatch({
                                type: BADGE_AWARDED,
                                payload: badge.badge,
                            });
                        }

                        //Set token to Auth header
                        setAuthToken(token);
                        //GA custom event for student logging in
                        ReactGA.event({
                            category: CATEGORIES.STUDENT_ACTIONS,
                            action: `${EVENTS.LOG_IN}`,
                        });

                        //Decode token to get user data
                        const decoded = await parseJwt(token.replace('Bearer ', ''));

                        try {
                            const profile = await axios.get(`/api/v4/users/${decoded.id}`);
                            dispatch(setUser({ ...profile.data, alias: decoded.alias }));
                            dispatch(setLearningSupport(profile.data.learningSupport === 1));

                            ampli.identify(profile.data.id, {
                                license: licenseTypes[profile.data.user_groups.licenseId],
                                role: roleTypes[profile.data.user_groups.roleId],
                                payment_status: profile.data.user_groups.paymentStatus || 'free',
                                group_id: roleTypes[profile.data.user_groups.groupId],
                                school_id: profile.data.schoolId,
                            });
                        } catch (error) {
                            console.log({ error });
                        }
                    } else if (result && result.response && +result.response.status === 401) {
                        localStorage.removeItem('authToken');
                    }
                });
            }
        }
    } catch (err) {
        console.log('Error setting logged in student: \n', err, '\n ==========\n');
        dispatch({
            type: GET_ERRORS,
            payload: err.error,
        });
    }
};

//Set Logged in user
export const setCurrentTeacher = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const setCurrentStudent = (decoded) => {
    return {
        type: SET_CURRENT_STUDENT,
        payload: decoded,
    };
};

export const setLearningSupport = (status) => {
    return { type: SET_LEARNING_SUPPORT, payload: status };
};

export const logOutUser = () => {
    //clear cookie
    axios.delete('/api/v4/auth/logout');

    //remove token from axios header
    setAuthToken(false);
    localStorage.removeItem('authToken');

    //pass to reducer to clear user data
    return { type: CLEAR_USER, payload: '' };
};

export const setUser = (userData) => {
    return userData.userType && userData.userType === 'teacher'
        ? { type: SET_CURRENT_USER, payload: userData }
        : { type: SET_CURRENT_STUDENT, payload: userData };
};

//show error notifications
export const notify = (data) => async (dispatch) => {
    dispatch(showNotification(Notification.SUCCESS, data));
};

//get updated StudentData
export const updatedStudentData = (studentId) => async (dispatch) => {
    try {
        await Users.getUserProfile(studentId).then((result) => {
            const studentData = result.data;
            if (studentData) {
                //update Student Data on Redux Auth

                const studentUpdateData = {
                    studentId: studentData.id,
                    name: studentData.name,
                    seeds: studentData.seeds,
                    grade: studentData.gradeId,
                    learningSupport: studentData.learningSupport,
                };

                dispatch({ type: UPDATE_STUDENT_AUTH_DATA, payload: studentUpdateData });
            }
        });
    } catch (err) {
        console.log('Error getting student data: \n', err);
    }
};

export const setCurrentGradeId = (gradeId) => async (dispatch) => {
    try {
        dispatch({ type: SET_CURRENT_GRADEID, payload: gradeId });
        return true;
    } catch (err) {
        console.log('Error setting gradeId: \n', err);
    }
};

export const logOutAlias = (data) => async (dispatch) => {
    return await axios.post(`/api/v4/auth/logout-alias`, data).then(
        (
            r //redirect to teacher
        ) =>
            Object.assign(document.createElement('a'), {
                href:
                    window.location.hostname.includes('lifeskillsgo') && window.location.hostname.includes('staging')
                        ? `//student-staging.lifeskillsgo.com`
                        : !window.location.hostname.includes('lifeskillsgo')
                        ? `//localhost:3001`
                        : `//student.lifeskillsgo.com`,
            }).click()
    );
};
