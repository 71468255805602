import {
    SET_CURRENT_USER,
    SET_CURRENT_STUDENT,
    SET_UNAUTHORIZED_TEACHER,
    CLEAR_USER,
    UPDATE_STUDENT_AUTH_DATA,
    SET_LEARNING_SUPPORT,
    SET_CURRENT_GRADEID,
} from '../actions/actionTypes';
import isEmpty from '../../validation/isEmpty';

const initialState = {
    isAuthenticated: false,
    teacher: {},
    student: {},
    learningSupport: false,
    isTeacher: false,
    currentGradeId: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                teacher: action.payload,
                isTeacher: action.payload && action.payload.userType && action.payload.userType === 'teacher',
                currentGradeId:
                    !isEmpty(action.payload.id) && !isNaN(action.payload.gradeId)
                        ? Math.floor((action.payload.gradeId + 1) / 2)
                        : null,
            };
        case SET_CURRENT_STUDENT:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                student: action.payload,
                currentGradeId:
                    !isEmpty(action.payload.id) && !isNaN(action.payload.gradeId)
                        ? Math.floor((action.payload.gradeId + 1) / 2)
                        : null,
                isTeacher: action.payload && action.payload.userType && action.payload.userType === 'teacher',
            };
        case SET_UNAUTHORIZED_TEACHER:
            return {
                ...state,
                unauthorized: Boolean(action.payload),
            };
        case CLEAR_USER:
            return {
                ...state,
                ...initialState,
            };
        case UPDATE_STUDENT_AUTH_DATA:
            return {
                ...state,
                student: {
                    ...state.student,
                    ...action.payload,
                },
                currentGradeId:
                    !isEmpty(action.payload.id) && !isNaN(action.payload.gradeId)
                        ? Math.floor((action.payload.gradeId + 1) / 2)
                        : null,
                learningSupport: action.payload && +action.payload.learningSupport === 1,
            };
        case SET_LEARNING_SUPPORT:
            return {
                ...state,
                learningSupport:
                    action.payload && typeof action.payload === 'boolean'
                        ? action.payload
                        : action.payload && +action.payload.learningSupport === 1,
            };
        case SET_CURRENT_GRADEID:
            return {
                ...state,
                currentGradeId: action.payload,
            };
        default:
            return state;
    }
}
