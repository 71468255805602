import { Api } from '@lsgo/lsgo-fe';
import { addBadge } from 'store/actions/uiActions';
import { addToScore, updateSeeds } from 'store/actions/skillActions';
//redux store and action
import store from 'store';

const responseInterceptor = (response) => {
    if (response.data) {
        if (response.data.badge && response.data.badge.badge && response.data.badge.badge.length > 0) {
            store.dispatch(addBadge(response.data.badge));
        }
        if (response.data.scores) {
            Object.keys(response.data.scores).forEach((scoreName) => {
                store.dispatch(addToScore(scoreName, response.data.scores[scoreName]));
            });
        }
        if (response.data.updatedSeeds) {
            store.dispatch(updateSeeds(response.data.updatedSeeds));
        }
    }
    return response;
};

const version = '1.0.0'

export const API = new Api({
    location: window.location,
    application: `lsgo_student_application_${version}`,
    responseInterceptor: responseInterceptor,
});

export default API.axiosInstance;
