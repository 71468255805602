const ENV_GOOGLE_ANALYTICS_ID = window.location.hostname.includes('-staging') ? 'UA-129313868-3' : 'UA-129313868-5';
export const ID = ENV_GOOGLE_ANALYTICS_ID;

export const EVENTS = {
    LOG_IN: 'LogIn',
    START_LESSON: 'StartLesson',
};

export const CATEGORIES = {
    STUDENT_ACTIONS: 'StudentActions',
};
