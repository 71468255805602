import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import isEmpty from 'validation/isEmpty';
import { toast } from 'react-toastify';
import { openNotification } from 'store/actions/uiActions';
import { showServerNotification, toggleModal, platformNotifications } from 'store/actions/uiActions';

import './toast.scss';

const ToastifyNotification = (props) => {
    if (
        (isEmpty(props.serverNotifications) || props.serverNotifications.message === '') &&
        isEmpty(props.platformNotifications)
    ) {
        return null;
    }

    const functionObject = {
        password: () => props.toggleModal(true, 'reauthenticate'),
    };

    const message = !isEmpty(props.serverNotifications) ? props.serverNotifications.message : '';
    const children = !isEmpty(props.serverNotifications) ? props.serverNotifications.children : [];
    const timeOut = !isEmpty(props.serverNotifications) ? props.serverNotifications.timeOut : null;
    const notificationTitle = !isEmpty(props.platformNotification)
        ? props.platformNotification.data.notificationTitle
        : '';
    const notificationMessage = !isEmpty(props.platformNotification)
        ? props.platformNotification.data.notificationMessage
        : '';
    const platformNotificationsData = !isEmpty(props.platformNotification) ? props.platformNotification.data : {};
    const onClick = !isEmpty(props.serverNotifications) ? functionObject[props.serverNotifications.onClick] : null;

    if (message) {
        props.showServerNotification('', [], null);
    }

    if (notificationTitle) {
        props.platformNotifications({}, '', null);
    }

    const getButtons = (children) => {
        return children.map((c) => {
            return (
                <Button
                    variant='contained'
                    color={c.color}
                    onClick={() => {
                        return window.open(c.link);
                    }}
                    key={c.key}
                >
                    {c.label}
                </Button>
            );
        });
    };

    const getWords = (str) => {
        if (str) {
            return str.split(/\s+/).slice(0, 5).join(' ');
        }
    };

    if (message && message !== '') {
        return toast(
            <>
                <div style={{ textAlign: 'center', padding: 10 }} onClick={onClick}>
                    {message}
                    <div style={{ padding: 10 }}>{getButtons(children)}</div>
                </div>
            </>,
            {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: timeOut,
            }
        );
    } else if (notificationTitle && notificationTitle !== '') {
        return toast(
            <>
                <div
                    style={{ textAlign: 'center', padding: 10 }}
                    onClick={(e) => {
                        props.openNotification(platformNotificationsData);
                    }}
                >
                    {notificationTitle}
                    <div style={{ padding: 10 }}>{`${getWords(notificationMessage)}...`}</div>
                </div>
            </>,
            {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: timeOut,
            }
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state) => ({
    serverNotifications: state.ui.serverNotifications,
    auth: state.auth,
    platformNotification: state.ui.platformNotifications,
});

const mapDispatchToProps = (dispatch) => {
    return {
        openNotification: (notificationObject) => dispatch(openNotification(notificationObject)),
        showServerNotification: (message, children, timeOut) =>
            dispatch(showServerNotification(message, children, timeOut)),
        toggleModal: (modalState, modalName) => dispatch(toggleModal(modalState, modalName)),
        platformNotifications: (data, level, levelId) => dispatch(platformNotifications(data, level, levelId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastifyNotification);
