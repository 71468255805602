import { INIT_STUDENTS, SET_CLASS } from '../actions/actionTypes';

const initialState = {
    classes: null,
    currentClass: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_STUDENTS:
            const classes = action.payload.result;
            const currentClass = action.payload.currentClass;
            return {
                ...state,
                classes,
                currentClass,
            };
        case SET_CLASS: {
            let currentClass = action.payload;
            localStorage.setItem(
                'currentClassId',
                currentClass && typeof currentClass === 'object' ? String(currentClass.classId) : String(currentClass)
            );
            return {
                ...state,
                currentClass,
            };
        }
        default:
            return state;
    }
}
