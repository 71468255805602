import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PrivateRoute from 'routing/PrivateRoute';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import Header from 'components/UI/Header';
import StudentAlert from 'components/StudentAlert';

/** Images */
import background from '../../assets/images/student/StudentViewFinalSmall.jpg';
import CustomColors from 'assets/customisedMUI/CustomColors';
import { Backdrop, Typography } from '@mui/material';
import { setLSRest } from 'store/actions/uiActions';
import { closeAllSockets } from 'store/actions/socketActions';
import { logOutUser } from 'store/actions/authActions';
import axios from 'config/axios';

import Footer from 'components/UI/Footer';

/**Styles */
import './studentLayout.scss';

const StudentLayout = ({ component: Component, path, auth, LSRest, setLSRest, isTeacher, ...rest }) => {
    const [isLearningSupport, setIsLearningSupport] = useState(false);
    const [supportLSRest, setSupportLSRest] = useState(false);
    const [atRest, setAtRest] = useState(false);
    const { params } = rest.computedMatch;

    // Set tracking user based on authentication, run when the auth state changes.
    useEffect(() => {
        if (auth.isAuthenticated === true) {
            try {
                ReactGA.set({ userId: `student-${auth.student.id}` });
            } catch (e) {
                console.error(e);
            }
        }
    }, [auth]);

    useEffect(() => {
        const fetchLSSchools = async () => {
            await axios.get(`/api/v4/constants/options/learningSupportSchools`).then((res) => {
                if (res.data && auth.student) {
                    const schoolsList = res.data.option;

                    setSupportLSRest(
                        JSON.parse(schoolsList).includes(isTeacher ? auth.teacher.schoolId : auth.student.schoolId)
                    );
                }
            });
        };
        fetchLSSchools();
    }, []);

    useEffect(() => {
        if (auth.learningSupport) {
            setIsLearningSupport(auth.learningSupport);
        } else {
            setIsLearningSupport(false);
        }
    }, [auth.learningSupport]);

    useEffect(() => {
        if (LSRest && supportLSRest) {
            setAtRest(LSRest);
        } else {
            setAtRest(false);
        }
    }, [LSRest]);

    const handleLogout = async () => {
        console.log('logging out');
        await rest.logOutUser();
        console.log({ studentLayout: 'closing socket' });
        await rest.closeAllSockets();
        setTimeout(() => {
            rest.history.push('/login');
        }, 100);
    };

    return (
        <div
            className={`student-main highschool ${auth.student && auth.student.alias ? 'alias' : ''}`}
            style={{
                ...(auth?.student?.gradeId < 7 ? { backgroundImage: `url(${background})` } : { background: '#f4f6f9' }),
            }}
        >
            <Header path={path} logout={handleLogout} history={rest.history} />
            <div className='content'>
                {isLearningSupport && supportLSRest && (
                    <div
                        className='shadow rest-div'
                        onClick={() => {
                            setLSRest(!LSRest);
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: atRest ? CustomColors('red') : CustomColors('blue'),
                            }}
                            className='text-center rest-text-div'
                        >
                            <Typography variant='h5'>Rest</Typography>
                        </div>
                    </div>
                )}
                <PrivateRoute
                    {...rest}
                    routeType='private'
                    isAuthenticated={auth.isAuthenticated}
                    render={(props) => <Component {...rest} />}
                />
            </div>

            <Footer />

            <StudentAlert />

            <Backdrop open={atRest} style={{ zIndex: 100, backgroundColor: 'transparent' }} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    isTeacher: state.auth.isTeacher,
});

const mapDispatchToProps = (dispatch) => {
    return {
        closeAllSockets: () => dispatch(closeAllSockets()),
        logOutUser: () => dispatch(logOutUser()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentLayout));
