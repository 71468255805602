import { ADD_TO_SCORE, SUBTRACT_FROM_SCORE, UPDATE_SEEDS } from './actionTypes';

export const addToScore = (type, score) => (dispatch) => {
    dispatch({ type: ADD_TO_SCORE, payload: { type: type, score: score } });
};

export const subtractFromScore = (type, score) => (dispatch) => {
    dispatch({
        type: SUBTRACT_FROM_SCORE,
        payload: { type: type, score: score },
    });
};

export const updateSeeds = (seeds) => (dispatch) => {
    dispatch({
        type: UPDATE_SEEDS,
        payload: { seeds },
    });
};
