import React from 'react';
import './BadgeModal.scss';
import Backdrop from '@mui/material/Backdrop';
import { Typography, Button } from '@mui/material';
import { connect } from 'react-redux';
import { removeBadge } from 'store/actions/uiActions';
import isEmpty from 'validation/isEmpty';

const congratulationsOptions = ['Congratulations', 'Great work', 'Awesome job'];
const nextButtonOptions = ['Awesome', 'Wow', 'Great'];

const BadgeModal = (props) => {
    if (isEmpty(props.ui.badges)) {
        return null;
    }

    const displayBadge = props.ui.badges[0];
    return (
        <Backdrop open={true} invisible={false} className='badge-modal-backdrop'>
            <div className='badge-modal'>
                <div className='badge-image'>
                    <img className='front' src={displayBadge.icon} alt={displayBadge.name} />

                    <Typography variant='body1' style={{ margin: 10 }}>
                        {displayBadge.awardCriteria}
                    </Typography>
                </div>
                <div className='section-text'>
                    <Typography variant='h4' paragraph>
                        {congratulationsOptions[Math.floor(Math.random() * congratulationsOptions.length)]}! You have
                        been awarded the '{displayBadge.name}' badge!
                    </Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            console.log('REMOVING BADGE');
                            props.removeBadge();
                        }}
                    >
                        {nextButtonOptions[Math.floor(Math.random() * nextButtonOptions.length)]}!
                    </Button>
                </div>
            </div>
        </Backdrop>
    );
};

const mapStateToProps = (state) => ({
    ui: state.ui,
});
const mapDispatchToProps = (dispatch) => {
    return { removeBadge: () => dispatch(removeBadge()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeModal);
