import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { parseJwt } from 'utils';
import { createSocket_v2, closeAllSockets } from 'store/actions/socketActions';
import { logOutUser } from 'store/actions/authActions';
import partlyBored from 'assets/images/student/weather/Cloudy_Bored.gif';
import Backdrop from 'components/UI/Backdrop/Backdrop';

const SocketHolder = (props) => {
    const [socketInterval, setSocketInterval] = useState(null);

    const isTeacher =
        props.isTeacher || (props.auth.teacher && props.auth.teacher.userType === 'teacher' ? true : false);
    const TeacherLessonToken = props.TeacherLessonToken;
    useEffect(() => {
        console.log('Websocket hook called.');
        let currentClassId;

        if (!isTeacher && props.classes) {
            currentClassId = props.classes.currentClass;
        } else {
            if (TeacherLessonToken) {
                //setauth for loggedin user
                parseJwt(TeacherLessonToken.replace('Bearer ', '')).then((decodedToken) => {
                    currentClassId = String(decodedToken.payload.classId);
                });
            }
        }

        // If user is not a teacher and lessonStopped is true, log the student out.
        if (!isTeacher && props.socketStudent.lessonStopped && props.socketStudent.lessonStopped.status) {
            props.logOutUser();
        }

        // Attempting to re-connect if connection drops.
        const classSocket = props.socketCommon.connectedSockets.includes(String(currentClassId));

        //  Socket not connected, lesson room not joined, schedule re-connect interval.
        if (!classSocket && !socketInterval) {
            if (localStorage.authToken) {
                if (!isTeacher && props.classes && props.classes.currentClass) {
                    const interval = setInterval(
                        () => props.createSocket_v2(String(props.classes.currentClass), localStorage.authToken),
                        5000
                    );
                    setSocketInterval(interval);
                } else if (isTeacher) {
                    const interval = setInterval(
                        () => props.createSocket_v2(currentClassId, localStorage.authToken),
                        5000
                    );
                    setSocketInterval(interval);
                }
            }
        }
        // Socket connected, lesson room
        else if (classSocket && socketInterval) {
            clearInterval(socketInterval);
            setSocketInterval(null);
        }

        return () => {
            if (socketInterval) {
                clearInterval(socketInterval);
                setSocketInterval(null);
            }
        };

        //eslint-disable-next-line
    }, [props.socketCommon.connectedSockets, props.socketStudent, props.classes]);

    console.log(isTeacher);

    return (
        <>
            {!isTeacher &&
                props.socketStudent.lessonPaused &&
                String(props.socketStudent.lessonPaused.classId) === String(props.classes.currentClass) && (
                    <Backdrop
                        show={props.socketStudent.lessonPaused.status && !isTeacher}
                        clicked={() => {
                            return false;
                        }}
                    >
                        <img src={partlyBored} alt='pause' />
                    </Backdrop>
                )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        currentLesson: state.lessons.currentLesson,
        socketCommon: state.socketCommon,
        socketStudent: state.socketStudent,
        classes: state.classes,
    };
};

const mapDispatchToProps = {
    createSocket_v2,
    closeAllSockets,
    logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketHolder);
