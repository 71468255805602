import React, { useState } from 'react';
import ConfirmDialog from 'components/UI/ConfirmAlert';

const NotificationPopOver = (props) => {
    const [open, setOpen] = useState(false);
    // const contentStyle = {
    //     color: '#000',
    //     width: props.alertWidth ? props.alertWidth : 400,
    //     textAlign: 'center',
    // };

    // if (props.color) {
    //     contentStyle['backgroundColor'] = props.color;
    //     contentStyle['borderColor'] = props.color;
    // }

    return (
        <>
            {/* <button className='btn btn-primary' style={{ display: 'none' }}}>
                Notification PopOver
            </button> */}
            <ConfirmDialog
                severity='error'
                question={props.title}
                message={props.message}
                open={open}
                onClose={() => setOpen(!open)}
                confirmCallback={() =>
                    window.open('https://www.microsoft.com/en-au/microsoft-365/windows/end-of-ie-support')
                }
                confirmName='More information'
                dismissName='Close'
            ></ConfirmDialog>
        </>
    );
};

export default NotificationPopOver;
