export const GET_ERRORS = 'GET_ERRORS';
export const GET_FATAL_ERRORS = 'GET_FATAL_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESETED = 'PASSWORD_RESETED';
export const SET_UNAUTHORIZED_TEACHER = 'SET_UNAUTHORIZED_TEACHER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_LEARNING_SUPPORT = 'SET_LEARNING_SUPPORT';

//UI ACTIONS
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_MODAL_BADGES = 'TOGGLE_MODAL_BADGES';
export const PAUSE_STUDENT = 'PAUSE_STUDENT';
export const TOGGLE_STUDENT_OVERLAY = 'TOGGLE_STUDENT_OVERLAY';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const BADGE_AWARDED = 'BADGE_AWARDED';
export const REMOVE_BADGE = 'REMOVE_BADGE';
export const BADGE_CACHED = 'BADGE_CACHED';
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';
export const PLATFORM_NOTIFICATIONS = 'PLATFORM_NOTIFICATIONS';
export const SERVER_NOTIFICATIONS = 'SERVER_NOTIFICATIONS';
export const SET_LS_REST = 'SET_LS_REST';

//CLASSES
export const ADD_CLASS = 'ADD_CLASS';
export const ADD_CLASS_V2 = 'ADD_CLASS_V2';
export const INIT_CLASSES = 'INIT_CLASSES';
export const REMOVE_CLASS = 'REMOVE_CLASS';
export const EDIT_CLASS = 'EDIT_CLASS';
export const SHOW_CLASS_LOADER = 'SHOW_CLASS_LOADER';
export const HIDE_CLASS_LOADER = 'HIDE_CLASS_LOADER';

export const REMOVE_UNIT = 'REMOVE_UNIT';
export const UPDATE_CURRICULUM = 'UPDATE_CURRICULUM';

export const GET_CLASS_DATA = 'GET_CLASS_DATA';
export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';
export const SET_CLASS = 'SET_CLASS';

export const ADD_STUDENT_TO_CLASS_LIST = 'ADD_STUDENT_TO_CLASS_LIST';

//STUDENTS
export const INIT_STUDENTS = 'INIT_STUDENTS';
export const ADD_STUDENT = 'ADD_STUDENT';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const EDIT_STUDENT = 'EDIT_STUDENT';
export const ADD_STUDENTS = 'ADD_STUDENTS';
export const STUDENT_DONATED = 'STUDENT_DONATED';
export const ADD_STUDENTS_TO_STATE = 'ADD_STUDENTS_TO_STATE';
export const UPDATE_STUDENT_AUTH_DATA = 'UPDATE_STUDENT_AUTH_DATA';
export const SET_STUDENT_ACTIVITY = 'SET_STUDENT_ACTIVITY';

//TEACHERS
export const SET_TEACHER_ACTIVITY = 'SET_TEACHER_ACTIVITY';

//learning area & focus
export const INIT_LEARNING_AREAS = 'INIT_LEARNING_AREAS';
export const ADMIN_ADD_LEARNING_FOCUS = 'ADMIN_ADD_LEARNING_FOCUS';
export const ADMIN_EDIT_LEARNING_FOCUS = 'ADMIN_EDIT_LEARNING_FOCUS';
export const ADMIN_DELETE_LEARNING_FOCUS = 'ADMIN_DELETE_LEARNING_FOCUS';
export const ADMIN_FOCUS_LOADER = 'ADMIN_FOCUS_LOADER';

//lessons
export const ADMIN_ADD_LESSON = 'ADMIN_ADD_LESSON';
export const ADMIN_EDIT_LESSON = 'ADMIN_EDIT_LESSON';
export const ADMIN_DELETE_LESSON = 'ADMIN_DELETE_LESSON';
export const GET_LESSON_BY_ID = 'GET_LESSON_BY_ID';
export const SET_CURRENT_LESSON = 'SET_CURRENT_LESSON';
export const SET_LESSON2_ACTIVITIES = 'SET_LESSON2_ACTIVITIES';
export const SET_ATTEMPT_ID = 'SET_ATTEMPT_ID';
export const SET_CURRENT_GRADEID = 'SET_CURRENT_GRADEID';

//units
export const SET_UNIT = 'SET_UNIT';

//activities
export const ADMIN_ADD_ACTIVITY = 'ADMIN_ADD_ACTIVITY';
export const GET_ACTIVITIES_BY_TYPE = 'GET_ACTIVITIES_BY_TYPE';
export const SHOW_ADD_ACTIVITY_LOADER = 'SHOW_ADD_ACTIVITY_LOADER';
export const HIDE_ADD_ACTIVITY_LOADER = 'HIDE_ADD_ACTIVITY_LOADER';

//REDUCER
export const SAVE_CURRICULUM = 'SAVE_CURRICULUM';

// alerts
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_LAST_ALERT';

// skill scores
export const SUBTRACT_FROM_SCORE = 'SUBTRACT_FROM_SCORE';
export const ADD_TO_SCORE = 'ADD_TO_SCORE';
export const UPDATE_SEEDS = 'UPDATE_SEEDS';

/** SOCKET ACTION TYPES */

// Base socket action types.
export const CREATE_SOCKET = 'CREATE_SOCKET';
export const CLOSE_SOCKET = 'CLOSE_SOCKET';
export const CLOSE_ALL_SOCKETS = 'CLOSE_ALL_SOCKETS';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';

// Shared socket action types.
export const JOIN_LESSON = 'JOIN_LESSON';

// Student-specific socket action types.
export const STUDENT_LESSON_PAUSE = 'STUDENT_LESSON_PAUSE';
export const STUDENT_LESSON_RESUME = 'STUDENT_LESSON_RESUME';
export const STUDENT_LESSON_STOP = 'STUDENT_LESSON_STOP';
export const SOCKET_STUDENT_ACTIVITY_DATA = 'SOCKET_STUDENT_ACTIVITY_DATA';
export const SOCKET_STUDENT_WR_DATA = 'SOCKET_STUDENT_WR_DATA';
export const SOCKET_STUDENT_MESSAGE = 'SOCKET_STUDENT_MESSAGE';
export const SOCKET_STUDENT_MESSAGE_RECEIVE = 'SOCKET_STUDENT_MESSAGE_RECEIVE';
export const STUDENT_BADGE_AWARDED = 'STUDENT_BADGE_AWARDED';

// Teacher-specific socket action types.
export const TEACHER_LESSON_PAUSE = 'TEACHER_LESSON_PAUSE';
export const TEACHER_LESSON_RESUME = 'TEACHER_LESSON_RESUME';
export const TEACHER_LESSON_STOP = 'TEACHER_LESSON_STOP';
export const SOCKET_TEACHER_ACTIVITY_DATA = 'SOCKET_TEACHER_ACTIVITY_DATA';
export const SOCKET_TEACHER_MESSAGE = 'SOCKET_TEACHER_MESSAGE';
export const SOCKET_TEACHER_MESSAGE_RECEIVE = 'SOCKET_TEACHER_MESSAGE_RECEIVE';
export const SOCKET_TEACHER_WR_DATA = 'SOCKET_TEACHER_WR_DATA';

export const CLEAR_ALL_ACTIVITY_RECORDS = 'CLEAR_ALL_ACTIVITY_RECORDS';
export const CLEAR_LESSON_ACTIVITY_RECORDS = 'CLEAR_LESSON_ACTIVITY_RECORDS';
export const CLEAR_STUDENT_ACTIVITY_RECORDS = 'CLEAR_STUDENT_ACTIVITY_RECORDS';
export const CLEAR_SINGLE_ACTIVITY_RECORD = 'CLEAR_SINGLE_ACTIVITY_RECORD';
export const CLEAR_WR_DATA = 'CLEAR_WR_DATA';

export const STUDENT_SET_CURRENT_ACTIVITY = 'STUDENT_SET_CURRENT_ACTIVITY';
export const TEACHER_SET_CURRENT_ACTIVITY = 'TEACHER_SET_CURRENT_ACTIVITY';

//Constants
export const UPDATE_CONSTANT = 'UPDATE_CONSTANT';
