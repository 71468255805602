import { SET_UNIT } from 'store/actions/actionTypes';
const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_UNIT:
            return action.payload;

        default:
            return state;
    }
}
