import React, { Fragment } from 'react';
import './Spinner.css';
import { logOutUser } from 'store/actions/authActions';

/**
 *@returns JSX
 */
const Spinner = (props) => {
    return (
        <Fragment>
            <div className='Loader'></div>
            {props.message && <p className='text-center'>{props.message}</p>}
            {props.clearCache && (
                <div style={{ textAlign: 'center' }}>
                    <button
                        onClick={async () => {
                            console.log('clearing cache');
                            localStorage.removeItem('authToken');
                            logOutUser();
                            props.history.push('/login');
                            window.location.reload();
                        }}
                    >
                        Clear Cache
                    </button>
                </div>
            )}
        </Fragment>
    );
};

export default Spinner;
