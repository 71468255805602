import React from 'react';

export default function JournalView({ Component, removeBorder, endLesson, loadELA, isProfile, ela, noLesson, lesson }) {
    return (
        <div className={isProfile ? 'profile-view' : 'journal-only student-view'}>
            <div className={isProfile ? 'journal-profile' : `${removeBorder ? '' : 'lesson'} no-sidebar`}>
                <div className='activity-component lesson2-view'>
                    <div className='activity-screen'>
                        <div className='student-activities'>
                            <Component
                                noLesson
                                endLesson={endLesson}
                                loadELA={(type) => loadELA(type)}
                                ela={ela}
                                isProfile={isProfile}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
