import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { AppBar, IconButton, Typography, Avatar, Badge, Menu, MenuItem, Button } from '@mui/material';
import { getClasses, setClass } from 'store/actions/studentsActions';

import { connect } from 'react-redux';
import { logOutUser } from 'store/actions/authActions';
import { closeAllSockets } from 'store/actions/socketActions';
import { Link } from 'react-router-dom';
import NavTabs from 'components/UI/NavTabs';
import Auth from 'api/Auth';
import { logOutAlias } from 'store/actions/authActions';
import isEmpty from 'validation/isEmpty';

import clsx from 'clsx';
import theme from 'assets/customisedMUI/theme';

/**-------------------- IMAGE ASSETS --------------------**/
import logo from 'assets/images/logo_long_horizontal.svg';
import Users from 'api/Users';

const menus = {
    profile: [{ title: 'Logout', action: 'onLogOutTeacher', redirectLink: '/login' }],
};

const Header = (props) => {
    // const classes = useStyles();
    const { isAdminView, history } = props;
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menu, setMenu] = useState('profile');
    const [studentClasses, setStudentClasses] = useState([]);
    const [classMenuAnchor, setClassMenuAnchor] = useState(null);
    const [selectedClassIndex, setSelectedClassIndex] = useState();

    const isStudent = !isEmpty(props.profile);

    const closeMenu = () => {
        setMenuAnchor(null);
    };
    const handleMenuItemClick = (menuItem) => {
        if (menuItem.link) {
            if (menuItem.type && menuItem.type !== 'appcues') {
                window.open(menuItem.link, '_blank');
            } else if (menuItem.type === 'appcues') {
                history.push(menuItem.link[Object.keys(menuItem.link).find((key) => key === props.location.pathname)]);
            } else {
                history.push(menuItem.link);
            }
        } else if (menuItem.action) {
            props[menuItem.action]();
            if (menuItem.redirectLink) {
                props.history.push(menuItem.redirectLink);
            }
        }
    };
    useEffect(() => {
        const fetchStudentClasses = async (studentId) => {
            const result = await Users.getClasses(studentId);
            if (result) {
                setStudentClasses(result);
                props.getClasses({ classList: result, currentClass: result.length === 1 ? result[0] : null });
            }
        };
        props.profile.id && fetchStudentClasses(props.profile.id);
        //eslint-disable-next-line
    }, [props.profile.id]);

    const handleClassMenuOpen = (event) => {
        setClassMenuAnchor(event.currentTarget);
    };

    const handleClassMenuClose = () => {
        setClassMenuAnchor(null);
    };

    return (
        <div className='hidden-print'>
            <AppBar
                id='lsgo-header'
                position='fixed'
                className={clsx({
                    'header-level-one': true,
                    'admin-view': isAdminView,
                    alias: props.profile.alias,
                })}
                sx={{
                    zIndex: theme.zIndex.drawer + 1,
                    transition: theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    backgroundColor: theme.palette.blue.backgroundColor,
                }}
            >
                {props.profile && props.profile.alias && (
                    <Typography
                        id='demo-notification'
                        variant='body1'
                        component='div'
                        display='block'
                        className='demo-info-block p-2'
                        style={{
                            width: '100%',
                            fontFamily: 'Museo700',
                            textAlign: 'center',
                            fontSize: 12,
                            backgroundColor: 'yellow',
                            color: 'rgba(0, 0, 0, 0.87)',
                        }}
                    >
                        You are viewing Life Skills GO as {props.profile.name}. To return to your dashboard click{' '}
                        <button
                            onClick={async () => {
                                await props.logOutAlias();
                                await props.closeAllSockets();
                            }}
                            style={{
                                background: 'none !important',
                                border: 'none',
                                padding: '0 !important',
                                color: '#069',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            here
                        </button>
                    </Typography>
                )}
                <div className='app-bar-container'>
                    <div className='app-bar-left'>
                        <div className='button-container'>
                            <Link
                                to={'/'}
                                title='LSGO'
                                data-pagename='SIDEBAR'
                                data-objectname='DASHBOARD_LOGO'
                                className='logo-header-hs'
                            >
                                <img src={logo} className='logo-header-img hidden-print' alt='Life Skills GO' />
                            </Link>

                            <div className='header-class-text'>
                                <Typography variant='h6'>
                                    {props.headerLabel
                                        ? props.headerLabel
                                        : props.currentClass && props.currentClass.className}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {!props.isTeacher && (
                        <div className='app-bar-center high-school'>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    id='lg-classlist'
                                    aria-controls='simple-menu'
                                    aria-haspopup='true'
                                    color='primary'
                                    variant='contained'
                                    onClick={(e) => {
                                        handleClassMenuOpen(e);
                                    }}
                                    style={{ alignSelf: 'center' }}
                                >
                                    <b style={{ lineHeight: '2.3' }}></b>
                                    {studentClasses.find(
                                        (classes) =>
                                            String(classes.classId) === String(props?.currentClass?.classId) ||
                                            String(classes.classId) === String(props?.currentClass)
                                    )
                                        ? studentClasses.find(
                                              (classes) =>
                                                  String(classes.classId) === String(props?.currentClass?.classId) ||
                                                  String(classes.classId) === String(props?.currentClass)
                                          ).className
                                        : ' Select Class'}
                                    &nbsp;
                                    <i className=' ml-2 fa fa-chevron-down' />
                                </Button>
                            </div>

                            <NavTabs
                                hasClass={!!props?.currentClass?.classId}
                                isAdminView={false}
                                classId={props?.currentClass?.classId}
                                isTeacher={props.isTeacher}
                                scrollable
                            />
                        </div>
                    )}
                    <div className='app-bar-right'>
                        <div className='profile-options'>
                            <div>
                                <div className='profile-option-container'>
                                    <div className='profile-option-inner'>
                                        <div className='option-row'>
                                            <Badge
                                                overlap='circular'
                                                variant='dot'
                                                aria-controls='simple-menu'
                                                aria-haspopup='true'
                                                style={{ color: '#fff', textTransform: 'none' }}
                                                id='notification-bell-icon'
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                className={'user-profile'}
                                                classes={{
                                                    badge: props.connectedSockets.includes(
                                                        String(props.currentClass && props.currentClass.classId)
                                                    )
                                                        ? { backgroundColor: '#00a000' }
                                                        : null,
                                                }}
                                            >
                                                <Avatar
                                                    onClick={(e) => {
                                                        setMenu('profile');
                                                        setMenuAnchor(e.currentTarget);
                                                    }}
                                                    variant='circular'
                                                    alt={props.profile && props.profile.name}
                                                    className={
                                                        ['blue', 'brown', 'red'][
                                                            props.profile &&
                                                                props.profile.name &&
                                                                props.profile.name
                                                                    .split(' ')
                                                                    .map((substring) => substring[0])
                                                                    .join('')[0]
                                                                    .toUpperCase()
                                                                    .charCodeAt(0) % 3
                                                        ]
                                                    }
                                                >
                                                    {props.profile &&
                                                        props.profile.name &&
                                                        props.profile.name
                                                            .split(' ')
                                                            .map((substring) => substring[0])
                                                            .join('')}
                                                </Avatar>
                                            </Badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppBar>
            {props.hasClass && (
                <AppBar
                    id='lsgo-header'
                    position='fixed'
                    className={clsx({
                        'header-level-two': true,
                        'admin-view': isAdminView,
                        hidden: props.headerLabel && !isAdminView ? true : false,
                        alias: props.profile.alias,
                    })}
                    sx={{
                        zIndex: theme.zIndex.drawer + 1,
                        transition: theme.transitions.create(['width', 'margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        backgroundColor: theme.palette.blue.backgroundColor,
                    }}
                >
                    <div className='app-bar-center hidden-print high-school'>
                        <NavTabs
                            scrollable
                            isAdminView={false}
                            hasClass={!!props?.currentClass?.classId}
                            isHighSchool={true}
                            classId={props?.currentClass?.classId}
                        />
                    </div>
                </AppBar>
            )}
            <Menu
                id='lg-student-classlist'
                anchorEl={classMenuAnchor}
                open={Boolean(classMenuAnchor)}
                onClose={handleClassMenuClose}
                style={{ color: 'black' }}
            >
                {isStudent &&
                    studentClasses.map((sc, index) => {
                        return (
                            <MenuItem
                                key={sc.classId}
                                selected={index === selectedClassIndex}
                                onClick={async (e) => {
                                    setSelectedClassIndex(index);
                                    setClassMenuAnchor(null);
                                    console.log('header set class');
                                    props.setClass(sc);
                                    props.history.push(
                                        `/${props.isTeacher ? 'teacher' : 'student'}/classes/${sc.classId}`
                                    );
                                }}
                                style={{ minWidth: 200, color: 'black' }}
                            >
                                {sc.className}
                            </MenuItem>
                        );
                    })}
            </Menu>
            <Menu
                className='header-menu'
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
                variant='menu'
            >
                {menus[menu].map((item) => {
                    if (item.type === 'appcues' && !Object.keys(item.link).includes(props.location.pathname))
                        return null;
                    return (
                        <MenuItem
                            key={item.title}
                            onClick={() => {
                                handleMenuItemClick(item);
                                closeMenu();
                            }}
                        >
                            {item.title} <i className={`fas fa-${item.type}`} style={{ fontSize: 12, marginLeft: 8 }} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.student,
        isTeacher: !!(state.auth.teacher && state.auth.teacher.id),
        currentClass: state?.classes.currentClass,
        connectedSockets: state.socketCommon.connectedSockets,
        hasClass: state.classes.classes && state.classes.classes.length > 0,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getClasses: (studentId) => dispatch(getClasses(studentId)),
        setClass: (classId) => dispatch(setClass(classId)),
        onLogOutTeacher: () => dispatch(logOutUser()),
        logOutAlias: (data) => dispatch(logOutAlias(data)),
        closeAllSockets: () => dispatch(closeAllSockets()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
