export const licenseTypes = {
    1: 'full_product',
    2: 'full_product',
    3: 'emotion_checkin',
    4: 'emotion_checkin',
    5: 'free_trial',
    6: 'free_trial',
};

export const roleTypes = {
    1: 'LS_admin',
    3: 'school_admin',
    4: 'teacher',
    5: 'student',
};
