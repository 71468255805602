import React, { useEffect, useState } from 'react';
import axios from 'config/axios';

/**
 *@returns JSX
 */
const Footer = () => {
    const [versionNumber, setVersionNumber] = useState('2023.05.01');
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    useEffect(() => {
        async function fetchVersion() {
            await axios.get(`/api/v4/options/version`).then((res) => {
                try {
                    const v = res.data.option;
                    if (v) {
                        setVersionNumber(v);
                    }
                } catch (e) {
                    console.log(`W: failed to update version number ${e}`);
                }
            });
        }
       // fetchVersion();
    }, []);

    return (
        <div id='footer' className='jq-toast-wrap top-right'>
            <div id='versionNumber' className='jq-toast-wrap top-right'>
                <a href='https://help.lifeskillsgroup.com.au/life-skills-go-change-list'>
                    <span>Version: v{versionNumber}</span>
                </a>
            </div>
            {currentYear} &copy; Life Skills Group Online Pty Ltd.
            <button
                onClick={() => {
                    console.log('RELOADING APP');
                    window.location.reload(true);
                }}
            >
                Reload
            </button>
        </div>
    );
};

export default Footer;
