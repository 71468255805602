import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

import socketMiddleware from '../utils/clientSocket';

//middleware
const logger = store => {
    return next => {
        return action => {
            const result = next(action);

            return result;
        };
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk, logger, socketMiddleware)));

export default store;
