import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: { main: '#13B5EA', contrastText: '#ffffff' },
        secondary: { main: '#666766', contrastText: '#ffffff' },
        pink: { backgroundColor: '#e20177', color: '#ffffff' },
        blue: { backgroundColor: '#13B5EA', color: '#ffffff' },
        grey: { backgroundColor: '#666766', color: '#ffffff' },
        orange: { backgroundColor: '#f78e1e', color: '#ffffff' },
        green: { backgroundColor: '#C1D82F', color: '#ffffff' },
        mustard: { backgroundColor: '#E1D04D', color: '#ffffff' },
        teal: { backgroundColor: '#4FB7AA', color: '#ffffff' },
        red: { backgroundColor: '#D73816', color: '#ffffff' },
    },
    typography: {
        fontFamily: ['Imprima'].join(','),
    },
    tableHeadCell: {
        style: {
            backgroundColor: '#EEF2F3',
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'left',
            fontFamily: 'Imprima',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: 'none',
                    marginLeft: '10px',
                    marginRight: '10px',
                    fontFamily: 'Imprima',
                },
                outlined: {
                    textTransform: 'none',
                    fontFamily: 'Imprima',
                },
            },
        },
        MuiFab: {
            extended: {
                textTransform: 'none',
                fontFamily: 'Imprima',
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    '&.admin-view': {
                        background: 'linear-gradient(105deg, white 199px, #f4f6f9 199px)',
                    },
                    background: 'linear-gradient(105deg, #f4f6f9 199px, #f4f6f9 199px)',
                    minHeight: '64px !important',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Museo700',
                    padding: '23px 12px',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: 'hsl(120, 0%, 40%)',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    height: 10,
                    width: 108,
                },
            },
        },
        MuiTabScrollButton: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.87)',
                },
            },
        },
    },
});

export default theme;
