import {
    TOGGLE_MODAL,
    TOGGLE_STUDENT_OVERLAY,
    PAUSE_STUDENT,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    BADGE_AWARDED,
    REMOVE_BADGE,
    BADGE_CACHED,
    OPEN_NOTIFICATION,
    SERVER_NOTIFICATIONS,
    PLATFORM_NOTIFICATIONS,
    SET_LS_REST,
} from '../actions/actionTypes';

const initialState = {
    showModal: false,
    dashboardModal: 'none',
    studentOverlay: false,
    pauseActivity: false,
    notification: {
        type: undefined,
        message: null,
    },
    badges: [],
    badgesCached: 0,
    beta: true,
    openNotification: {},
    learningSupportRest: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MODAL:
            return {
                ...state,
                showModal: action.payload.showModal,
                dashboardModal: action.payload.dashboardModal || 'none',
            };
        case TOGGLE_STUDENT_OVERLAY:
            return {
                ...state,
                studentOverlay: action.payload.studentOverlay,
            };
        case PAUSE_STUDENT:
            return {
                ...state,
                pauseActivity: action.payload.pause,
            };
        case SHOW_NOTIFICATION:
            return {
                ...state,
                notification: {
                    type: action.payload.notificationType,
                    message: action.payload.content,
                },
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                notification: {},
            };
        case OPEN_NOTIFICATION:
            const { notificationId, notifiedAt } = action.payload;
            return {
                ...state,
                openNotification: {
                    notificationId: notificationId,
                    notifiedAt: notifiedAt,
                },
            };
        case SERVER_NOTIFICATIONS:
            return {
                ...state,
                serverNotifications: {
                    message: action.payload.content,
                    children: action.payload.children,
                    timeOut: action.payload.timeOut,
                    onClick: action.payload.onClick,
                },
            };
        case PLATFORM_NOTIFICATIONS:
            const { data, level, levelId } = action.payload;
            const idOfLevel = levelId ? `${level}-${levelId}` : null;
            return {
                ...state,
                platformNotifications: {
                    data: data,
                    level: idOfLevel,
                },
            };
        case BADGE_AWARDED:
            return {
                ...state,
                badges: [...state.badges, ...action.payload],
            };
        case REMOVE_BADGE:
            const newBadges = [...state.badges];
            newBadges.splice(0, 1);
            return {
                ...state,
                badges: newBadges,
            };
        case BADGE_CACHED:
            return { ...state, badgesCached: action.payload };
        case SET_LS_REST:
            return { ...state, learningSupportRest: action.payload };
        default:
            return state;
    }
}
