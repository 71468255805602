import {
    TEACHER_LESSON_PAUSE,
    TEACHER_LESSON_RESUME,
    TEACHER_LESSON_STOP,
    SOCKET_TEACHER_WR_DATA,
    SOCKET_TEACHER_ACTIVITY_DATA,
    CLEAR_ALL_ACTIVITY_RECORDS,
    CLEAR_LESSON_ACTIVITY_RECORDS,
    CLEAR_SINGLE_ACTIVITY_RECORD,
    CLEAR_WR_DATA,
    SOCKET_TEACHER_MESSAGE,
    SOCKET_TEACHER_MESSAGE_RECEIVE,
    SET_TEACHER_ACTIVITY,
} from '../../actions/actionTypes';

const initialState = {
    /** Holds arrays of student activity data for specific lessons, indexed by lesson Id. */
    classActivityData: {},
    /** Holds array of student Emotion Check-in data. */
    wrActivityData: [],
    messages: [],
    lessonPaused: { status: false },
    lessonStopped: { status: false },
    currentActivity: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        /** Action intercepted by socket middleware, used to fire event - no state update required. */
        case TEACHER_LESSON_PAUSE: {
            return {
                ...state,
                lessonPaused: { classId: String(action.payload.classId), status: true },
            };
        }
        /** Action intercepted by socket middleware, used to fire event - no state update required. */
        case TEACHER_LESSON_RESUME: {
            return {
                ...state,
                lessonPaused: { status: false },
                lessonStopped: { status: false },
            };
        }
        /** Action intercepted by socket middleware, used to fire event - no state update required. */
        case TEACHER_LESSON_STOP: {
            return {
                ...state,
                lessonStopped: { classId: String(action.payload.classId), status: true },
            };
        }
        case SET_TEACHER_ACTIVITY: {
            const { classId, unitId, lessonId, activityId } = action.payload;
            return {
                ...state,
                currentActivity: { classId: classId, unitId: unitId, lessonId: lessonId, activityId: activityId },
            };
        }
        /** On receipt of student WR data via socket.IO connection, append to array in store. */
        case SOCKET_TEACHER_WR_DATA: {
            return {
                ...state,
                wrActivityData: [...state.wrActivityData, action.payload],
            };
        }
        /** On receipt of student activity data via socket.IO connection. */
        case SOCKET_TEACHER_ACTIVITY_DATA: {
            let lessonData = state.classActivityData[action.payload.lessonId];

            if (!lessonData) {
                lessonData = [action.payload];
            } else {
                lessonData = [...lessonData, action.payload];
            }

            return {
                ...state,
                classActivityData: { ...state.classActivityData, [action.payload.lessonId]: lessonData },
            };
        }
        /** Clear the store of all student activity data. */
        case CLEAR_ALL_ACTIVITY_RECORDS: {
            return {
                ...state,
                classActivityData: {},
            };
        }
        /** Clear the store of student activity all data for a particular lesson. */
        case CLEAR_LESSON_ACTIVITY_RECORDS: {
            delete state.classActivityData[action.payload.lessonId];

            return {
                ...state,
                classActivityData: { ...state.classActivityData },
            };
        }
        /** Clear the store of a particular student activity record for a specific lesson. */
        case CLEAR_SINGLE_ACTIVITY_RECORD: {
            const lessonData = state.classActivityData[action.payload.lessonId];

            if (!lessonData) {
                return state;
            }

            return {
                ...state,
                classActivityData: {
                    ...state.classActivityData,
                    [action.payload.lessonId]: lessonData.filter(
                        (record) => record.recordId === action.payload.recordId
                    ),
                },
            };
        }
        /** Clear the store of WR activity data.*/
        case CLEAR_WR_DATA: {
            return {
                ...state,
                wrActivityData: [],
            };
        }
        case SOCKET_TEACHER_MESSAGE_RECEIVE: {
            return { ...state, messages: [...state.messages, action.payload] };
        }
        default: {
            return state;
        }
    }
}
