import {
    ADD_TO_SCORE,
    SUBTRACT_FROM_SCORE,
    SET_CURRENT_STUDENT,
    UPDATE_STUDENT_AUTH_DATA,
    SET_CURRENT_LESSON,
    UPDATE_SEEDS,
} from '../actions/actionTypes';

const initialState = {
    scores: {
        brain: 0,
        earth: 0,
        body: 0,
        heart: 0,
    },
    seeds: 0,
};

function addToScore(scores, action) {
    const newScores = { ...scores };
    if (!(action.type in newScores)) {
        newScores[action.type] = 0;
    }
    newScores[action.type] += action.score;
    return newScores;
}

function subtractFromScore(scores, action) {
    const newScores = { ...scores };
    if (!(action.type in newScores)) {
        newScores[action.type] = 0;
    }
    if (newScores[action.type] > 0) newScores[action.type] -= action.score;
    return newScores;
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TO_SCORE:
            return {
                ...state,
                scores: addToScore(state.scores, action.payload),
            };

        case SUBTRACT_FROM_SCORE:
            return {
                ...state,
                scores: subtractFromScore(state.scores, action.payload),
            };
        case UPDATE_SEEDS:
            return {
                ...state,
                seeds: state.seeds + action.payload.seeds,
            };
        case SET_CURRENT_STUDENT:
            return { ...state, seeds: action.payload.seeds };

        case UPDATE_STUDENT_AUTH_DATA:
            return {
                ...state,
                seeds: action.payload.seeds,
            };
        case SET_CURRENT_LESSON: {
            return {
                ...state,
                scores: {
                    brain: 0,
                    earth: 0,
                    body: 0,
                    heart: 0,
                },
            };
        }
        default:
            return state;
    }
}
