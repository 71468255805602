import axios from 'config/axios';
import Auth from './Auth';
import isEmpty from 'validation/isEmpty';

class Users {
    static async getClasses(id, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/users/${id}/classes?include=${opts.include || ''}`).then((res) => res.data);
    }

    static async getLessons({ classId, userId }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(`/api/v4/lessons/users/${userId}/classes/${classId}/?${opts.gradeId ? `gradeId=${opts.gradeId}` : ''}`)
            .then((res) => res.data);
    }

    static async getClassLessons({ classId }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(`/api/v4/lessons/?classId=${classId}${!isEmpty(opts.stage) ? `&stage=${opts.stage}` : ''}`)
            .then((res) => res.data);
    }

    static async getBadges({ userId }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/users/${userId}/badges`).then((res) => res.data);
    }

    static async getClassSettings({ classId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/classes/${classId}/settings`).then((res) => res.data);
    }

    static async pushCheckInAnalytics({ classId, userId, data }) {
        await Auth.checkCurrentAuthToken();
        return await axios.post(`/api/v4/analytics/checkIn/classes/${classId}/users/${userId}`, data);
    }

    static async createCheckInCommentByStudent({ recordId, studentCommentId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/analytics/checkIn/${recordId}/student-comment`, {
            recordId,
            studentCommentId,
        });
    }

    static async getCommentCategories() {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/schools/commentCategories`).then((r) => r.data);
    }
}

export default Users;
