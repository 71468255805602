import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';

const PrivateRoute = ({ routeType, auth, accountExpired, history, ...props }) => {
    const { isAuthenticated } = auth;
    const isExpired = !moment().isBefore(moment(auth.expiryDate));
    const { redirectUri } = queryString.parse(new URL(window.location.href).search);
    if (isAuthenticated) {
        if (redirectUri) {
            return <Redirect to={redirectUri} />;
        } else if (routeType === 'guest') {
            return <Redirect to='/' />;
        }
        // else if (isAuthenticated && isExpired && !history.location.pathname.includes('/teacher/profile')) {
        //     return <Redirect to='/teacher/profile' />;
        // }
    } else {
        if (routeType === 'sentral')
            return (
                <Redirect
                    to={`/?redirectUri=${encodeURIComponent(
                        history.location.pathname + history.location.search
                    )}&auth_method=sentral_sso`}
                />
            );
        if (routeType === 'private' || !routeType) {
            return (
                <Redirect
                    to={`/login?redirectUri=${encodeURIComponent(history.location.pathname + history.location.search)}`}
                />
            );
        }
    }

    return <Route {...props} />;
};

export default withRouter(
    connect((state) => {
        return { auth: state.auth };
    })(PrivateRoute)
);
