import {
    CREATE_SOCKET,
    CLOSE_SOCKET,
    CLOSE_ALL_SOCKETS,
    JOIN_LESSON,
    SOCKET_CONNECTED,
    SOCKET_DISCONNECTED,
} from '../../actions/actionTypes';

/** Keep a running log of connected sockets, indexed by class Id. */
const initialState = {
    connectedSockets: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        /** Action intercepted by clientSocket middleware and used to instantiate sockets. */
        case CREATE_SOCKET: {
            console.log('(CR) attempting to create socket.', action.payload.classId);
            return state;
        }
        /** Action intercepted by clientSocket middleware and used to destroy sockets. */
        case CLOSE_SOCKET: {
            console.log('(CR) attempting to close socket.', action.payload.classId);
            const newConnectedSocketsArray = state.connectedSockets.filter(
                (socket) => String(socket) !== String(action.payload.classId)
            );
            return {
                ...state,
                connectedSockets: newConnectedSocketsArray,
            };
        }
        /** Action intercepted by clientSocket middleware - used to destroy all sockets. */
        case CLOSE_ALL_SOCKETS: {
            console.log('(CR) attempting to close all sockets.');
            return {
                ...state,
                connectedSockets: [],
            };
        }
        // case JOIN_LESSON: {
        //     return state;
        // }
        /** On successful connection of socket to server in 'clientSocket.js', the socket dispatches a 'SOCKET_CONNECTED' action. */
        case SOCKET_CONNECTED: {
            console.log('(CR) socket connected: ', action.payload.classId);
            const socketClassId = String(action.payload.classId);
            return {
                ...state,
                connectedSockets: [...state.connectedSockets, socketClassId],
            };
        }
        /** On disconnection of socket from server in 'clientSocket.js', the socket dispatches a 'SOCKET_DISCONNECTED' action. */
        case SOCKET_DISCONNECTED: {
            console.log('(CR) socket disconnected: ', action.payload.classId);
            const socketClassId = String(action.payload.classId);
            return {
                ...state,
                connectedSockets: state.connectedSockets.filter((classId) => classId !== socketClassId),
            };
        }
        default: {
            return state;
        }
    }
}
