import { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Tab, Tabs } from '@mui/material';
import { ampli } from 'ampli';
import theme from 'assets/customisedMUI/theme';

const tabOptions = [
    { title: 'Class Work', link: '<classlink>', isAdminView: false, isTeacherView: true },
    { title: 'Emotion Check-in', link: '/checkin', isAdminView: false, requiresClass: true },
    { title: 'Quiet Place / Journal', link: '/quiet-place', isAdminView: false, requiresClass: true },
    { title: 'Profile', link: '/profile', isAdminView: false },
];

const NavTabs = (props) => {
    const { location, hidden, isAdminView, history, scrollable, isTeacher } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const currentTabOptions = tabOptions.filter(
        (option) => option.isAdminView === isAdminView && (!isTeacher || option.isTeacherView === !!isTeacher)
    );

    const handlePageChange = useCallback(
        (e, newTab) => {
            ampli.buttonClicked({ button_name: currentTabOptions[newTab].title, location: 'Top Navigation Bar' });
            history.push(
                currentTabOptions[newTab].link === '<classlink>'
                    ? props.currentClass?.classId
                        ? `/student/classes/${props.currentClass.classId}`
                        : '/'
                    : currentTabOptions[newTab].link
            );
        },
        [currentTabOptions, history]
    );

    useEffect(() => {
        let newTab = tabOptions.find((option) => {
            {
                return location.pathname.includes(option.link);
            }
        });
        if (!newTab) {
            newTab = tabOptions[0];
        }
        const newTabIndex = tabOptions.indexOf(newTab);
        if (newTabIndex >= 0) {
            const newTabOptions = tabOptions.filter(
                (option) => option.isAdminView === tabOptions[newTabIndex].isAdminView
            );
            setCurrentTab(newTabOptions.indexOf(newTab));
        }
    }, [location]);

    return hidden ? null : (
        <Tabs
            scrollButtons={scrollable ? true : false}
            allowScrollButtonsMobile
            value={currentTab}
            onChange={handlePageChange}
            style={{ flex: props.isHighSchool ? '1 1 100' : '1 1 411px' }}
            variant='scrollable'
        >
            {currentTabOptions.map((tab, index) => {
                const selected = index === currentTab;
                return (
                    <Tab
                        key={index}
                        label={tab.title}
                        disabled={tab.requiresClass && !props.classes.currentClass}
                        sx={{
                            textTransform: 'none',
                            fontWeight: theme.typography.fontWeightRegular,
                            marginRight: theme.spacing(3),
                            fontFamily: 'Museo700',
                        }}
                    ></Tab>
                );
            })}
        </Tabs>
    );
};

export default withRouter(
    connect((state) => {
        return { classes: state.classes, currentClass: state.classes.currentClass };
    })(NavTabs)
);
