import isEmpty from 'validation/isEmpty';
import {
    TOGGLE_MODAL,
    TOGGLE_STUDENT_OVERLAY,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    CLEAR_ERRORS,
    BADGE_AWARDED,
    REMOVE_BADGE,
    BADGE_CACHED,
    OPEN_NOTIFICATION,
    PLATFORM_NOTIFICATIONS,
    SERVER_NOTIFICATIONS,
    SET_LS_REST,
} from './actionTypes';

export const toggleModal = (modalState, modalName) => {
    return {
        type: TOGGLE_MODAL,
        payload: { showModal: modalState, dashboardModal: modalName },
    };
};

export const toggleStudentOverlay = (overlayState) => {
    return {
        type: TOGGLE_STUDENT_OVERLAY,
        payload: { studentOverlay: overlayState },
    };
};

export const showNotification = (notificationType, content) => (dispatch) => {
    dispatch(showNotificationTimeOut(notificationType, content));

    setTimeout(() => {
        dispatch(hideNotification());
    }, 8000);
};

export const showNotificationTimeOut = (notificationType, content) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: { notificationType: notificationType, content: content },
    };
};

export const hideNotification = () => {
    return {
        type: HIDE_NOTIFICATION,
    };
};

export const cleanError = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

export const addBadge = (data) => {
    const { badge, cache } = data;
    if (cache.status) {
        return {
            type: BADGE_CACHED,
            payload: cache.total,
        };
    } else {
        return {
            type: BADGE_AWARDED,
            payload: badge,
        };
    }
};

export const removeBadge = () => {
    return {
        type: REMOVE_BADGE,
    };
};

export const openNotification = (notificationObject) => {
    return {
        type: OPEN_NOTIFICATION,
        payload: notificationObject,
    };
};

export const platformNotifications = (data, level, levelId) => {
    return {
        type: PLATFORM_NOTIFICATIONS,
        payload: { data: data, level: level, levelId },
    };
};

export const showServerNotification = (content, children, timeOut, onClick) => (dispatch) => {
    dispatch(showServerNotificationTimeOut(content, children, timeOut, onClick));
};

export const showServerNotificationTimeOut = (content, children, timeOut, onClick) => {
    return {
        type: SERVER_NOTIFICATIONS,
        payload: { content: content, children: children, timeOut, onClick },
    };
};

export const setLSRest = (value) => {
    return {
        type: SET_LS_REST,
        payload: value,
    };
};
