import {
    STUDENT_LESSON_PAUSE,
    STUDENT_LESSON_RESUME,
    STUDENT_LESSON_STOP,
    SOCKET_STUDENT_ACTIVITY_DATA,
    SOCKET_STUDENT_MESSAGE_RECEIVE,
    SOCKET_STUDENT_MESSAGE,
    SET_STUDENT_ACTIVITY,
} from '../../actions/actionTypes';

const initialState = {
    lessonPaused: { status: false },
    lessonStopped: { status: false },
    messages: [],
    currentActivity: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case STUDENT_LESSON_PAUSE: {
            console.log('student_lessonPause');
            const { classId } = action.payload;
            return {
                ...state,
                lessonPaused: { classId: String(classId), status: true },
            };
        }
        case STUDENT_LESSON_RESUME: {
            console.log('student_lessonResume');
            return {
                ...state,
                lessonPaused: { status: false },
                lessonStopped: { status: false },
            };
        }
        case STUDENT_LESSON_STOP: {
            console.log('student_lessonStop');
            return {
                ...state,
                lessonStopped: { classId: String(action.payload.classId), status: true },
            };
        }
        case SET_STUDENT_ACTIVITY: {
            const { classId, unitId, lessonId, activityId } = action.payload;
            return {
                ...state,
                currentActivity: { classId: classId, unitId: unitId, lessonId: lessonId, activityId: activityId },
            };
        }
        /** Action intercepted by middleware - no effect on store. */
        case SOCKET_STUDENT_ACTIVITY_DATA: {
            return state;
        }
        case SOCKET_STUDENT_MESSAGE: {
            return { ...state, messages: [...state.messages, { ...action.payload.message, me: true }] };
        }
        case SOCKET_STUDENT_MESSAGE_RECEIVE: {
            return { ...state, messages: [...state.messages, action.payload.message] };
        }
        default: {
            return state;
        }
    }
}
