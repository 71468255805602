export const TYPES = {
    //QUIZ TYPES
    QUIZ_MULTI_SINGLE: {
        ID: 101,
        TITLE: 'Single Answer',
        TYPE: 'QUIZ_MULTI_SINGLE',
        COMPONENT: 'Quiz',
        ICON: 'fa fa-list',
        // //sliderImage: require('assets/images/student/activities/quiz_multi_single.png'),
    },
    QUIZ_MULTI_MULTI: {
        ID: 102,
        TITLE: 'Multiple Answer',
        TYPE: 'QUIZ_MULTI_MULTI',
        COMPONENT: 'Quiz',
        ICON: 'fa fa-list',
        // sliderImage: require('assets/images/student/activities/quiz_multi_multi.png'),
    },
    QUIZ_TEXT_FIELD: {
        ID: 103,
        TITLE: 'Open ended',
        TYPE: 'QUIZ_TEXT_FIELD',
        COMPONENT: 'Quiz',
        ICON: 'fa fa-file',
        // sliderImage: require('assets/images/student/activities/quiz_text_response.png'),
    },
    QUIZ_DRAW_RESPONSE: {
        ID: 104,
        TITLE: 'Draw response',
        TYPE: 'QUIZ_DRAW_RESPONSE',
        COMPONENT: 'Quiz',
        ICON: 'fa fa-pencil',
        // sliderImage: require('assets/images/student/activities/quiz_draw_response.png'),
    },
    //ANIMATIONS
    ANIMATION: {
        ID: 200,
        TITLE: 'Animation',
        TYPE: 'ANIMATION',
    },
    //GAMES
    GAME: {
        ID: 300,
        TYPE: 'GAME',
        TITLE: 'Games',
        COMPONENT: 'Game',
        ICON: 'fa fa-gamepad',
        // sliderImage: require('assets/images/student/activities/game.png'),
    },

    //VIDEOS
    VIDEO: {
        ID: 400,
        TITLE: 'Videos',
        TYPE: 'VIDEO',
        COMPONENT: 'Video',
        ICON: 'fa fa-play',
        // sliderImage: require('assets/images/student/activities/character_animations.png'),
    },
    //STATIC VIDEOS
    WEATHERREPORT: {
        ID: 500,
        TITLE: 'Emotion Check-in',
        TYPE: 'WEATHERREPORT',
        COMPONENT: 'WeatherReport',
        ICON: 'fa fa-cloud',
        // sliderImage: require('assets/images/student/activities/weather_check_in.png'),
    },
    WEATHERCHECKIN: {
        ID: 600,
        TITLE: 'Weather Check In',
        TYPE: 'WEATHERCHECKIN',
        COMPONENT: 'WeatherCheckin',
        ICON: ' fa fa-cloud-upload',
        // sliderImage: require('assets/images/student/activities/weather_report.png'),
    },
};

export const WHEATHER_REPORT_DB_ID = 999999999998;
export const WHEATHER_CHECKIN_DB_ID = 999999999999;

export const ENDGAME_ID = 301;
export const ENDLESSON_JOURNAL = 302;

export const ACTIVITY_SUMMARY = {
    activityId: 999999999997,
    title: 'Summary',
    sliderImage: 'activity_summary',
    content: {},
    activityType: 'Summary',
};

export const GAME_TYPES = [
    { id: 300, type: 'General' },
    { id: 310, type: 'Scenario Based' },
];

const def = {};
export default def;
