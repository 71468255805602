import React from 'react';
import PrivateRoute from 'routing/PrivateRoute';

import background from '../../assets/images/student/StudentViewFinalSmall.jpg';
import logo from '../../assets/images/logo.png';
import Footer from '../../components/UI/Footer';

import { isIE } from '@lsgo/lsgo-common';
import NotificationPopOver from '../../components/UI/Notifications/NotificationPopOver';

import '../../containers/Student/student.scss';

const EmptyLayout = ({ component: Component, ...rest }) => {
    return (
        <>
            {isIE(document) && (
                <div className='notification-popover'>
                    <NotificationPopOver
                        title='Unsupported Browser'
                        message={`The version of Internet Explorer you are using is not supported by Life Skills GO. We recommend using Chrome or any modern internet browser.`}
                        alertStyle='danger'
                        alertWidth={500}
                    />
                </div>
            )}
            <div
                className='student-main-background container-fluid studentv2'
                style={{
                    backgroundImage: 'url(' + background + ')',
                }}
            >
                <div className='header'>
                    <div className='logo-header'>
                        <img className='logo cursor-pointer' src={logo} alt='lsgo logo' style={{ maxWidth: 87 }} />
                    </div>
                </div>
                <div className='col-12 main-studnet-content'></div>
                <div id='canvas-wrapper2'>
                    <div className='student-box2'>
                        <PrivateRoute {...rest} routeType='guest' render={(props) => <Component {...props} />} />
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default EmptyLayout;
