import { STUDENT_DONATED } from '../actions/actionTypes';

const initialState = {
    seedsCount: null,
    profileImage: null,
    name: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case STUDENT_DONATED: {
            return {
                ...state,
                seedsCount: action.payload.studentSeeds,
                // data: action.payload
            };
        }

        default:
            return state;
    }
}
