import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorsReducer from './errorReducer';
import uiReducer from './uiReducer';
import studentReducer from './studentReducer';
import classReducer from './classReducer';
import alertsReducer from './alertsReducer';
import skillsReducer from './skillsReducer';
import lessonReducer from './lessonReducer';
import unitReducer from './unitReducer';
import constantsReducer from './constantsReducer';

import socketReducerCommon from './sockets/socketReducerCommon';
import socketReducerStudent from './sockets/socketReducerStudent';
import socketReducerTeacher from './sockets/socketReducerTeacher';

export default combineReducers({
    auth: authReducer,
    errors: errorsReducer,
    ui: uiReducer,
    classes: classReducer,
    students: studentReducer,
    alerts: alertsReducer,
    skills: skillsReducer,
    lessons: lessonReducer,
    unit: unitReducer,
    socketCommon: socketReducerCommon,
    socketStudent: socketReducerStudent,
    socketTeacher: socketReducerTeacher,
    constants: constantsReducer,
});
