import { TYPES } from '../constants/activityTypes';
import { Buffer } from 'buffer';

export const getActivityByType = (type, key = null) => {
    const data = Object.entries(TYPES)
        .map((entry) => entry[1])
        .filter((entry) => +entry.ID === +type)
        .shift();

    if (data && key) {
        return data[key];
    } else {
        return data;
    }
};

export const parseJwt = (token) => {
    let base64Payload;
    let payload;
    return new Promise((resolve, reject) => {
        try {
            base64Payload = token.split('.')[1];
            payload = Buffer.from(base64Payload, 'base64');
            return resolve(JSON.parse(payload.toString()));
        } catch (e) {
            return reject(null);
        }
    });
};

export const getArrayOfUniqueObjects = (arr, uniqueIdentifier) => {
    const result = [];
    const map = new Map();
    for (const item of arr) {
        if (!map.has(item[uniqueIdentifier])) {
            map.set(item[uniqueIdentifier], true); // set any value to Map
            result.push(item);
        }
    }
    return result;
};

export default {
    getActivityByType: getActivityByType,
    parseJwt: parseJwt,
    getArrayOfUniqueObjects: getArrayOfUniqueObjects,
};
