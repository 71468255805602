/**
 * This module manages socket connections on the client.
 *
 * It will interface with the rest of the application through a redux-middleware function.
 */

import io from 'socket.io-client';
import { parseJwt } from 'utils';

// Base socket action types.
import {
    CREATE_SOCKET,
    CLOSE_SOCKET,
    CLOSE_ALL_SOCKETS,
    JOIN_LESSON,
    SOCKET_STUDENT_ACTIVITY_DATA,
    SOCKET_STUDENT_WR_DATA,
    TEACHER_LESSON_PAUSE,
    TEACHER_LESSON_RESUME,
    TEACHER_LESSON_STOP,
    SET_CLASS,
    REMOVE_CLASS,
    ADD_CLASS,
    SOCKET_STUDENT_MESSAGE,
    STUDENT_BADGE_AWARDED,
    BADGE_AWARDED,
    SET_TEACHER_ACTIVITY,
    SET_STUDENT_ACTIVITY,
} from '../store/actions/actionTypes';

import {
    socketConnected,
    socketDisconnected,
    student_LessonPause,
    student_LessonResume,
    student_LessonStop,
    teacher_socketActivityData,
    teacher_socketWRData,
    student_receiveMessage,
    student_receiveBadge,
    setTeacherActivity,
    setStudentActivity,
} from '../store/actions/socketActions';
import { WSServerLink } from '@lsgo/lsgo-fe';

/** ------------------------------------------------------------------------------------------------------------ */
/** ----------------------------------------SOCKET SET-UP DETAILS ---------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Socket connection URL. */
const SOCKET_URL = WSServerLink(window.location);

/** Default socket connection parameters. */
const socketParams = {
    forceNew: true,
    'force new connection': true,
    autoConnect: false,
    reconnectionAttempts: 1,
    reconnectionDelay: 1000,
    timeout: 10000,
    reconnectionDelayMax: 500,
};

/** Object to hold our active sockets, indexed by class ID. */
let activeSockets = [];
let activeSocketId = null;

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- STUDENT EVENT LISTENERS ------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Subscribe a socket to lesson pause events, dispatch 'STUDENT_LESSON_PAUSE' action on event. */
const student_subscribeLessonPause = (socket) => (dispatch) => {
    socket.on('lessonPause', (data) => {
        // console.log('Received Lesson Pause');
        dispatch(student_LessonPause(data));
    });
};

/** Subscribe a socket to lesson resume events, dispatch 'STUDENT_LESSON_RESUME' action on event. */
const student_subscribeLessonResume = (socket) => (dispatch) => {
    socket.on('lessonResume', (data) => {
        // console.log('Received Lesson Resume');
        dispatch(student_LessonResume(data));
    });
};

/** Subscribe a socket to lesson stop events, dispatch 'STUDENT_LESSON_STOP' action on event. */
const student_subscribeLessonStop = (socket) => (dispatch) => {
    socket.on('lessonStop', (data) => {
        // console.log('Received Lesson Stop');
        dispatch(student_LessonStop(data));
    });
};

const student_subscribeSetStudentActivity = (socket) => (dispatch) => {
    socket.on('setStudentActivity', (data) => {
        dispatch(setStudentActivity(data));
    });
};

const student_subscribeTeacherMessage = (socket) => (dispatch) => {
    socket.on('student_receiveMessage', (data) => {
        dispatch(student_receiveMessage(data));
    });
};

const student_subscribeBadgeAwarded = (socket) => (dispatch) => {
    socket.on('studentReceiveBadge', (data) => {
        dispatch(student_receiveBadge(data));
    });
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- STUDENT EVENT EMITTERS -------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** On dispatch of 'SOCKET_STUDENT_ACTIVITY_DATA action, emit 'studentActivityData' event through active socket. */
const student_emitStudentActivity = (report, classId) => {
    if (activeSockets.includes(String(classId))) {
        console.log('Student Emit Activity:', report);
        activeSocketId.emit('studentActivityData', { report, classId });
    }
};

const student_emitStudentWR = (report, classId) => {
    if (activeSockets.includes(String(classId))) {
        console.log('Student Emit WR:', report);
        activeSocketId.emit('studentWRData', { report, classId });
    }
};

const student_emitMessage = (message, classId) => {
    if (activeSockets.includes(String(classId))) {
        activeSocketId.emit('studentMessage', { message, classId });
    }
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- TEACHER EVENT LISTENERS ------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Subscribe a socket to lesson report events, dispatch 'SOCKET_TEACHER_ACTIVITY_DATA' action on event. */
const teacher_subscribeStudentActivity = (socket) => (dispatch) => {
    socket.on('teacher_studentActivityData', (data) => {
        console.log('teacher_subscribeStudentActivity', data);
        dispatch(teacher_socketActivityData(data));
    });
};

const teacher_subscribeStudentWR = (socket) => (dispatch) => {
    socket.on('teacher_studentWRData', (data) => {
        console.log('teacher_subscribeWRData', data);
        dispatch(teacher_socketWRData(data));
    });
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- TEACHER EVENT EMITTERS -------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** On dispatch of 'TEACHER_LESSON_PAUSE' action, emit 'lessonPause' event through class socket. */
const teacher_emitLessonPause = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) {
        // console.log('Emit Lesson Pause', classId, unitId, lessonId);
        activeSocketId.emit('lessonPause', { classId, unitId, lessonId });
    }
};

/** On dispatch of 'TEACHER_LESSON_RESUME' action, emit 'lessonResume' event through class socket. */
const teacher_emitLessonResume = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) {
        // console.log('Emit Lesson Resume', classId, unitId, lessonId);
        activeSocketId.emit('lessonResume', { classId, unitId, lessonId });
    }
};

/** On dispatch of 'TEACHER_LESSON_STOP' action, emit 'lessonStop' event through class socket. */
const teacher_emitLessonStop = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) {
        // console.log('Emit Lesson Stop', classId, unitId, lessonId);
        activeSocketId.emit('lessonStop', { classId, unitId, lessonId });
    }
};

const teacher_emitSetStudentActivity = (classId, unitId, lessonId, activityId) => {
    if (activeSockets.includes(String(classId))) {
        activeSocketId.emit('setStudentActivity', { classId, unitId, lessonId, activityId });
    }
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- COMMON EVENT EMITTERS --------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

// /** On dispatch of 'JOIN_LESSON' action, emit 'joinLesson' event through class socket. */
// const common_emitJoinLesson = (classId, unitId, lessonId) => {
//     if (activeSockets.includes(+classId)) activeSocketId.emit('joinLesson', { classId, unitId, lessonId });
// };

/** ------------------------------------------------------------------------------------------------------------ */
/** -------------------------------------- SOCKET CREATION/DESTRUCTION ----------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Create a new socket for a class (indexed by ID) if none exists. */
const createSocket = (classId, token) => (dispatch) => {
    if (!activeSocketId) {
        if (token) {
            /** Create a new socket, splitting out and passing the encrypted component of the JWT string. */
            const newSocket = io(SOCKET_URL, { ...socketParams, query: 'token=' + token.split(' ')[1] });

            /** Set-up actions on establishment of server connection. */
            newSocket.on('connect', () => {
                /** Socket ready for work, append to active socket list and return. */
                activeSocketId = newSocket;

                /** Subscribing to class-specific socket room on server-side. */
                activeSocketId.emit('joinClass', { classId });

                activeSockets.push(String(classId));

                /** Places the classId in a Redux store so that program knows web-socket is connected & available. */
                dispatch(socketConnected(classId));
            });

            /** Tear-down actions on loss of connection to server.*/
            newSocket.on('disconnect', () => {
                /** Removing classId from list of available web-sockets in redux store. */
                dispatch(socketDisconnected(classId));
                console.log('disconnecting', classId);
                /** Cleaning up activeSockets object. */
                closeAllSockets(classId);
            });

            /** Decoding JWT storage passed from local storage. */
            parseJwt(token.replace('Bearer ', '')).then((decodedToken) => {
                /** Attaching student-specific event-listeners if user is a student. */
                if ('userType' in decodedToken && decodedToken.userType === 'student') {
                    student_subscribeLessonPause(newSocket)(dispatch);
                    student_subscribeLessonResume(newSocket)(dispatch);
                    student_subscribeLessonStop(newSocket)(dispatch);
                    student_subscribeTeacherMessage(newSocket)(dispatch);
                    student_subscribeBadgeAwarded(newSocket)(dispatch);
                    student_subscribeSetStudentActivity(newSocket)(dispatch);
                }

                /** Attaching teacher-specific event-listeners if user is a student. */
                if ('userType' in decodedToken && decodedToken.userType === 'teacher') {
                    teacher_subscribeStudentActivity(newSocket)(dispatch);
                    teacher_subscribeStudentWR(newSocket)(dispatch);
                    student_subscribeLessonPause(newSocket)(dispatch);
                    student_subscribeLessonResume(newSocket)(dispatch);
                    // student_subscribeLessonStop(newSocket)(dispatch);
                }

                /** Connect to the server. */
                newSocket.connect();
            });
        }
    }

    const existingClass = activeSockets.find((cId) => cId === classId);

    if (!existingClass && activeSocketId) {
        /** Subscribing to class-specific socket room on server-side. */
        activeSocketId.emit('joinClass', { classId });
        activeSockets.push(String(classId));

        /** Places the classId in a Redux store so that program knows web-socket is connected & available. */
        dispatch(socketConnected(classId));
    }
};

/** Close a socket (indexed by class ID) if it exists. */
const closeSocket = (classId) => {
    console.log({ activeSocketId });
    if (activeSocketId) {
        activeSocketId.emit('leaveClass', { classId });
        console.log({ leaveClass: classId });
        activeSockets = classId ? activeSockets.filter((cId) => cId !== classId) : [];
    }
};

// /** Close all sockets except the one for the given class ID. */
// const closeManySockets = (classId) => {
//     Object.keys(activeSockets).forEach((key) => {
//         if (key !== classId) closeSocket(key);
//     });
// };

/** Close all sockets. */
const closeAllSockets = () => {
    activeSockets.map((classId) => closeSocket(String(classId)));
    activeSocketId = null;
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ------------------------------------------- SOCKET REDUX MIDDLEWARE ---------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Middleware to intercept actions meant to emit a socket event. */
const socketMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        /** Action to directly create a new socket and join a class. */
        case CREATE_SOCKET: {
            const { classId, token } = action.payload;
            if (token && classId) createSocket(classId, token)(store.dispatch);
            break;
        }
        /** Action to directly disable/remove a socket. */
        case CLOSE_SOCKET: {
            const { classId } = action.payload;
            console.log({ middlewareclosesocket: classId });
            closeSocket(classId);
            break;
        }
        case CLOSE_ALL_SOCKETS: {
            closeAllSockets();
            break;
        }
        // /** Join a socket.io room indexed by class, unit, and lesson ID (used for lesson pause/start/stop). */
        // case JOIN_LESSON: {
        //     const { classId, unitId, lessonId } = action.payload;
        //     common_emitJoinLesson(classId, unitId, lessonId);
        //     break;
        // }
        case SOCKET_STUDENT_ACTIVITY_DATA: {
            const { report, classId } = action.payload;
            student_emitStudentActivity(report, classId);
            break;
        }
        case SOCKET_STUDENT_WR_DATA: {
            const { report, classId } = action.payload;
            student_emitStudentWR(report, classId);
            break;
        }
        case SOCKET_STUDENT_MESSAGE: {
            const { message, classId } = action.payload;
            student_emitMessage(message, classId);
            break;
        }
        case STUDENT_BADGE_AWARDED: {
            const { data } = action.payload;
            const { badgeData } = data;
            const { studentId } = badgeData;
            const thisStudentId = store.getState().auth.student.id;
            if (+studentId === +thisStudentId) {
                store.dispatch({ type: BADGE_AWARDED, payload: [badgeData] });
            }
        }
        case TEACHER_LESSON_PAUSE: {
            const { classId, unitId, lessonId } = action.payload;
            teacher_emitLessonPause(classId, unitId, lessonId);
            break;
        }
        case TEACHER_LESSON_RESUME: {
            const { classId, unitId, lessonId } = action.payload;
            teacher_emitLessonResume(classId, unitId, lessonId);
            break;
        }
        case TEACHER_LESSON_STOP: {
            const { classId, unitId, lessonId } = action.payload;
            teacher_emitLessonStop(classId, unitId, lessonId);
            window.close();
            break;
        }
        case SET_TEACHER_ACTIVITY: {
            const { classId, unitId, lessonId, activityId } = action.payload;
            teacher_emitSetStudentActivity(classId, unitId, lessonId, activityId);
            break;
        }

        /** --------- Dirty hacks to ensure the connected websocket is synced to the currently viewed class ID. -------- */

        /**
         * It's not easy to determine which class is currently being viewed, as there are multiple sources of truth
         * that sometimes update independently of each other (e.g. redux store 'currentClass' in classReducer,
         * local storage 'currentClassId', etc.)
         *
         * The class Id stored in local storage seems to be the most accurate indicator.
         *
         * Intercepting actions that modify local storage variable, and using them to activate/deactivate sockets.
         */

        case SET_CLASS: {
            /** ID of new current class. */
            const classId = action.payload;

            // /** Disable all old sockets. */
            // closeManySockets(classId);
            /** Create a socket for the new class if required. */
            if (classId && localStorage.authToken) createSocket(classId, localStorage.authToken)(store.dispatch);
            break;
        }
        /** -------------------------------------------- End of dirty hacks. -------------------------------------------- */
    }

    return next(action);
};

export default socketMiddleware;
