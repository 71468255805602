import {
    CREATE_SOCKET,
    CLOSE_SOCKET,
    CLOSE_ALL_SOCKETS,
    JOIN_LESSON,
    SOCKET_CONNECTED,
    SOCKET_DISCONNECTED,
    STUDENT_LESSON_PAUSE,
    STUDENT_LESSON_RESUME,
    STUDENT_LESSON_STOP,
    SOCKET_STUDENT_ACTIVITY_DATA,
    SOCKET_STUDENT_MESSAGE,
    SOCKET_STUDENT_MESSAGE_RECEIVE,
    SOCKET_STUDENT_WR_DATA,
    TEACHER_LESSON_PAUSE,
    TEACHER_LESSON_RESUME,
    TEACHER_LESSON_STOP,
    SOCKET_TEACHER_MESSAGE,
    SOCKET_TEACHER_MESSAGE_RECEIVE,
    SOCKET_TEACHER_ACTIVITY_DATA,
    CLEAR_ALL_ACTIVITY_RECORDS,
    CLEAR_LESSON_ACTIVITY_RECORDS,
    CLEAR_SINGLE_ACTIVITY_RECORD,
    SOCKET_TEACHER_WR_DATA,
    CLEAR_WR_DATA,
    STUDENT_BADGE_AWARDED,
    SET_STUDENT_ACTIVITY,
    SET_TEACHER_ACTIVITY,
} from './actionTypes';

/** ------------------------------------------------------------------------------------------------------------ */
/** ----------------------------------------SOCKET-MANAGEMENT ACTIONS------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Action to create a socket for a class. */
export const createSocket_v2 = (classId, token) => {
    return {
        type: CREATE_SOCKET,
        payload: { classId, token },
    };
};

/** Action to close/destroy a classId's socket. */
export const closeSocket = (classId) => {
    console.log({ closeSocket: classId });
    return {
        type: CLOSE_SOCKET,
        payload: { classId },
    };
};

/** Action to close/destroy all currently connected sockets. */
export const closeAllSockets = () => {
    return {
        type: CLOSE_ALL_SOCKETS,
    };
};

/** Action dispatched on 'connected' event from that classId's socket. */
export const socketConnected = (classId) => {
    return {
        type: SOCKET_CONNECTED,
        payload: { classId },
    };
};

/** Action dispatched on 'disconnected' event from that classId's socket. */
export const socketDisconnected = (classId) => {
    return {
        type: SOCKET_DISCONNECTED,
        payload: { classId },
    };
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------STUDENT-SPECIFIC ACTIONS--------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Action dispatched on socket 'pause' event (emitted by teacher) to stop progress in student lesson. */
export const student_LessonPause = (data) => {
    return {
        type: STUDENT_LESSON_PAUSE,
        payload: data,
    };
};

/** Action dispatched on socket 'resumse' event (emitted by teacher) to allow progress in student lesson. */
export const student_LessonResume = (data) => {
    return {
        type: STUDENT_LESSON_RESUME,
        payload: data,
    };
};

/** Action dispatched on socket 'stop' event (emitted by teacher) to stop progress in student lesson, and log-out student. */
export const student_LessonStop = (data) => {
    return {
        type: STUDENT_LESSON_STOP,
        payload: data,
    };
};

export const setStudentActivity = (data) => (dispatch) => {
    dispatch({
        type: SET_STUDENT_ACTIVITY,
        payload: data,
    });
};

/** Action dispatched on completion of student activity, emits an event containing activity data to teacher. */
export const student_sendActivityData = (report, classId) => {
    return {
        type: SOCKET_STUDENT_ACTIVITY_DATA,
        payload: { report, classId },
    };
};

export const student_sendWRData = (report, classId) => {
    return {
        type: SOCKET_STUDENT_WR_DATA,
        payload: { report, classId },
    };
};

/** Action dispatched on student message, emits an event containing message to teacher. */
export const student_sendMessage = (message, classId) => {
    return {
        type: SOCKET_STUDENT_MESSAGE,
        payload: { message, classId },
    };
};

export const student_receiveMessage = (message) => {
    return {
        type: SOCKET_STUDENT_MESSAGE_RECEIVE,
        payload: message,
    };
};

export const student_receiveBadge = (data) => {
    return { type: STUDENT_BADGE_AWARDED, payload: { data } };
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------TEACHER-SPECIFIC ACTIONS -------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Action to pause a student lesson for a particular class. */
export const teacher_LessonPause = (classId, unitId, lessonId) => {
    return {
        type: TEACHER_LESSON_PAUSE,
        payload: { classId, unitId, lessonId },
    };
};

/** Action to pause a student lesson for a particular class. */
export const teacher_LessonResume = (classId, unitId, lessonId) => {
    return {
        type: TEACHER_LESSON_RESUME,
        payload: { classId, unitId, lessonId },
    };
};

/** Action to pause a student lesson for a particular class. */
export const teacher_LessonStop = (classId, unitId, lessonId) => {
    return {
        type: TEACHER_LESSON_STOP,
        payload: { classId, unitId, lessonId },
    };
};

export const setTeacherActivity = (classId, unitId, lessonId, activityId) => async (dispatch) => {
    dispatch({
        type: SET_TEACHER_ACTIVITY,
        payload: { classId, unitId, lessonId, activityId },
    });
};

/** Action dispatched on received socket-event containing student WR data. */
export const teacher_socketWRData = (data) => {
    return {
        type: SOCKET_TEACHER_WR_DATA,
        payload: data,
    };
};

/** Action dispatched on received socket-event containing student activity data. */
export const teacher_socketActivityData = (data) => {
    return {
        type: SOCKET_TEACHER_ACTIVITY_DATA,
        payload: data,
    };
};

/** Action dispatched to clear all activity data originating from web-sockets.*/
export const teacher_clearAllActivityRecords = () => {
    return {
        type: CLEAR_ALL_ACTIVITY_RECORDS,
    };
};

/** Action dispatched to clear activity data originating from web-sockets for a specific lesson ID.*/
export const teacher_clearLessonActivityRecords = (lessonId) => {
    return {
        type: CLEAR_LESSON_ACTIVITY_RECORDS,
        payload: { lessonId },
    };
};

/** Action dispatched to clear activity data originating from web-sockets for a specific record ID in a lesson.*/
export const teacher_clearSingleActivityRecord = (lessonId, recordId) => {
    return {
        type: CLEAR_SINGLE_ACTIVITY_RECORD,
        payload: { lessonId, recordId },
    };
};

/** Action dispatched to clear the redux store of WR data obtained via web-sockets. */
export const teacher_clearWRData = () => {
    return {
        type: CLEAR_WR_DATA,
    };
};

/** Action dispatched to send a message from teacher to class. */
export const teacher_sendMessage = (message, classId) => {
    return {
        type: SOCKET_TEACHER_MESSAGE,
        payload: { message, classId },
    };
};

/** Action dispatched to update teacher when a new message is received. */
export const teacher_receiveMessage = (message) => {
    return {
        type: SOCKET_TEACHER_MESSAGE_RECEIVE,
        payload: message,
    };
};

/** ------------------------------------------------------------------------------------------------------------ */
/** --------------------------------------------COMMON ACTIONS ------------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

// /** Action to join a specific unit/lesson session for a class. */
// export const joinLesson = (classId, unitId, lessonId) => {
//     return {
//         type: JOIN_LESSON,
//         payload: { classId, unitId, lessonId },
//     };
// };
