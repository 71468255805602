import React, { useEffect, useState, Suspense, lazy } from 'react';
import Loader from 'components/UI/Spinner/Spinner';
import { Switch, withRouter } from 'react-router-dom';

import ReactGA from 'react-ga4';
import { ID } from 'constants/googleAnalytics';

import StudentEmptyLayout from './hoc/StudentsLayouts/EmptyLayout';
import StudentLayout from './hoc/StudentsLayouts/StudentLayout';

import { connect } from 'react-redux';
import {
    setCurrentTeacher,
    setCurrentStudent,
    loginStudentQR,
    logOutUser,
    setUser,
    updatedStudentData,
    setLearningSupport,
} from 'store/actions/authActions';
import { parseJwt } from 'utils';
import setAuthToken from 'utils/setAuthToken';
import store from './store';

import BadgeModal from 'components/UI/Modal/BadgeModal';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastifyNotification from 'components/UI/Notifications/ToastifyNotification';

import JournalView from 'components/StudentV2/JournalView';
import SocketHolder from './containers/SocketHolder';
import Auth from 'api/Auth';

// Update the axios baseURL with .env variables (if prod vs if dev etc..)
import axios from 'config/axios';
import isEmpty from 'validation/isEmpty';
import { ampli } from './ampli';
import { licenseTypes, roleTypes } from 'constants/options';

//** Loadable Components */

const StudentLoginComponent = lazy(() => import('./containers/Student/StudentLogin'));
const StudentLogin = () => (
    <Suspense fallback={<Loader />}>
        <StudentLoginComponent />
    </Suspense>
);

const DashboardComponent = lazy(() => import('./containers/Dashboard'));
const Dashboard = () => (
    <Suspense fallback={<Loader />}>
        <DashboardComponent />
    </Suspense>
);

const GlobalGoalsComponent = lazy(() => import('./containers/Student/GlobalGoals'));
const GlobalGoals = () => (
    <Suspense fallback={<Loader />}>
        <GlobalGoalsComponent />
    </Suspense>
);

const JournalListComponent = lazy(() => import('./components/StudentV2/JournalList'));
const JournalList = () => (
    <Suspense fallback={<Loader />}>
        <JournalListComponent noLesson={true} />
    </Suspense>
);

const JournalActivityComponent = lazy(() => import('./components/Activities/JournalActivity'));
const JournalActivity = () => (
    <Suspense fallback={<Loader />}>
        <JournalActivityComponent noLesson={true} />
    </Suspense>
);

const QuietPlaceComponent = lazy(() => import('./containers/Student/QuietPlace/QuietPlace'));
const QuietPlace = () => (
    <Suspense fallback={<Loader />}>
        <QuietPlaceComponent noLesson={true} />
    </Suspense>
);

const JournalMainPage = () => {
    return (
        <div className='col-12' style={{ marginTop: '3vh' }}>
            <div className='card-box'>
                <JournalView Component={JournalList} isProfile={true} />
            </div>
        </div>
    );
};

const WeatherReportViewComponent = lazy(() => import('./components/StudentV2/WeatherReportView'));
const WeatherReportView = () => (
    <Suspense fallback={<Loader />}>
        <WeatherReportViewComponent />
    </Suspense>
);

const BadgesComponent = lazy(() => import('./components/StudentV2/Badges'));
const Badges = () => (
    <Suspense fallback={<Loader />}>
        <BadgesComponent />
    </Suspense>
);

const GoogleRegistrationComponent = lazy(() => import('components/Auth/GoogleRegistration'));
const GoogleRegistration = () => (
    <Suspense fallback={<Loader />}>
        <GoogleRegistrationComponent />
    </Suspense>
);

const ClassesComponent = lazy(() => import('containers/Classes'));
const Classes = () => (
    <Suspense fallback={<Loader />}>
        <ClassesComponent />
    </Suspense>
);

const ProfileComponent = lazy(() => import('containers/Profile/index'));
const Profile = () => (
    <Suspense fallback={<Loader />}>
        <ProfileComponent />
    </Suspense>
);

const PathHandlerComponent = lazy(() => import('containers/PathHandler/index'));
const PathHandler = () => (
    <Suspense fallback={<Loader />}>
        <PathHandlerComponent />
    </Suspense>
);

/** Main App for student interface */
const App = (props) => {
    const [authLoop, setAuthLoop] = useState(true);
    const [authenticationLoop, setAuthenticationLoop] = useState(null);

    useEffect(() => {
        //Google Analytics for pages
        props.history.listen((location, action) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        });
    }, []);

    //ComponentDidMount
    useEffect(() => {
        window.location.hostname.includes('lifeskillsgo') &&
            ampli.load({
                environment: window.location.hostname.includes('staging') ? 'development' : 'production',
            });

        const checkLoggedIn = async () => {
            await Auth.userV2({ history: props.history }).then(async (token) => {
                console.log(token);
                if (token) {
                    const decoded = await parseJwt(token.replace('Bearer ', ''));
                    if (decoded.id) {
                        try {
                            setAuthToken(token);
                            const studentProfile = await axios.get(`/api/v4/users/${decoded.id}/`);
                            props.setUser({ ...studentProfile.data, alias: decoded.alias });
                            props.setLearningSupport(studentProfile.data.learningSupport === 1);
                            ampli.identify(studentProfile.data.id, {
                                license: licenseTypes[studentProfile.data.user_groups.licenseId],
                                role: roleTypes[studentProfile.data.user_groups.roleId],
                                payment_status: studentProfile.data.user_groups.paymentStatus || 'free',
                                group_id: studentProfile.data.user_groups.groupId,
                                school_id: studentProfile.data.schoolId,
                            });

                            ReactGA.initialize(ID, {
                                debug: false,
                                titleCase: false,
                                gaOptions: {
                                    userId: `student-${decoded.id}`,
                                },
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        logOutUser();
                        await props.history.push('/login');
                    }
                }
            });

            setAuthLoop(false);
        };

        checkLoggedIn();

        setAuthenticationLoop(
            setInterval(() => {
                console.log('Auth loop running');
                if (store.getState().auth.isAuthenticated) checkLoggedIn();
            }, 60 * 59 * 2 * 1000)
        );

        return () => {
            console.warn('Clearing authentication loop');
            clearInterval(authenticationLoop);
            setAuthenticationLoop(null);
        };
        //eslint-disable-next-line
    }, []);

    /** return main app */
    return authLoop ? (
        <Loader history={props.history} clearCache />
    ) : (
        <>
            <BadgeModal />
            <Switch>
                <StudentEmptyLayout path='/qrLogin' component={PathHandler} />
                <StudentEmptyLayout path='/teacherView' component={PathHandler} />
                <StudentEmptyLayout path='/googleRegistration' component={GoogleRegistration} />
                <StudentLayout path='/rewards' component={GlobalGoals} />
                {/* <StudentLayout path='/:userType/classes/:classId/:assignmentId' component={Lesson} /> */}
                <StudentLayout path='/:userType/classes/:classId' component={Dashboard} />

                <StudentEmptyLayout path='/login' component={StudentLogin} />
                <StudentLayout path='/checkin' component={WeatherReportView} history={props.history} />
                <StudentLayout path='/profile' component={Profile} />
                <StudentLayout
                    exact
                    path='/journal/new'
                    component={() => <JournalView Component={JournalActivity} />}
                />
                <StudentLayout
                    exact
                    path='/journal/:journalId'
                    component={() => <JournalView Component={JournalActivity} />}
                />
                <StudentLayout path='/journal' component={JournalMainPage} />
                <StudentLayout path='/quiet-place' component={QuietPlace} />
                <StudentLayout component={Classes} />
            </Switch>
            <ToastifyNotification />
            <ToastContainer toastClassName='server-notification' autoClose={false} closeOnClick={false} />
            {/* <SocketHolder /> */}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        studentId: state.auth.student.id,
        currentLesson: state.lessons.currentLesson,
    };
};

const mapDispatchToPtops = (dispatch) => {
    return {
        setCurrentTeacher: (teacher) => dispatch(setCurrentTeacher(teacher)),
        setCurrentStudent: (student) => dispatch(setCurrentStudent(student)),
        setLearningSupport: (student) => dispatch(setLearningSupport(student)),
        loginStudentQR: (qrToken, history) => dispatch(loginStudentQR(qrToken, history)),
        setUser: (userData) => dispatch(setUser(userData)),
        updatedStudentData: (studentId) => dispatch(updatedStudentData(studentId)),
    };
};
export default connect(mapStateToProps, mapDispatchToPtops)(withRouter(App));
