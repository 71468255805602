import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material';
import './alertDialog.scss';

const ConfirmDialog = (props) => {
    return (
        <Dialog
            maxWidth='md'
            fullWidth={props.fullWidth || false}
            className='alert-dialog'
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle className='text-center'>{props.question}</DialogTitle>
            <DialogContent>
                {props.message}
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.dismissOverride ? props.dismissOverride : props.onClose}
                    variant='outlined'
                    color='secondary'
                >
                    {props.dismissName}
                </Button>
                <Button variant='contained' color='primary' onClick={props.confirmCallback}>
                    {props.confirmName}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;
