import { GET_ERRORS, CLEAR_ERRORS } from '../actions/actionTypes';

const initialState = {
    errors: {},
    fatalErrors: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                errors: {},
            };
        default:
            return state;
    }
}
